<template lang="pug">
    .btnArchive
        //- pre submission = {{submission}}
        v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
                v-btn.action( v-bind="attrs", v-on="on", icon, @click="archiveSubmission(submission)")
                    v-icon(:color="submission.archived ? $vuetify.theme.themes.light.archived : 'secondary lighten-2'") {{submission.archived ? 'mdi-archive-arrow-up-outline' : 'mdi-archive-arrow-down-outline' }}
            span.white--text {{submission.archived ? 'Un-Archive this submission' : 'Archive this submission'}}
</template>

<script>
import { call } from 'vuex-pathify'
export default {
  name: 'BtnArchive',
  props: { submission: { type: Object, required: true } },
  methods: {
    ...call({ archiveSubmission: 'submissions/setArchived' })
  }
}
</script>
