import { render, staticRenderFns } from "./DialogSubmissionCheck.vue?vue&type=template&id=cd368fac&scoped=true&lang=pug&"
import script from "./DialogSubmissionCheck.vue?vue&type=script&lang=js&"
export * from "./DialogSubmissionCheck.vue?vue&type=script&lang=js&"
import style0 from "./DialogSubmissionCheck.vue?vue&type=style&index=0&id=cd368fac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd368fac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCol,VDialog,VIcon,VRow,VTextarea})
