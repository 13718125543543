<template lang="pug">
    v-dialog(v-model="showSubmitTextValidation" :max-width="$vuetify.breakpoint.mdAndUp ? '50vw' : '90vw'" modal persistent :overlay-opacity="0.9").dialog-submission-check
        template( v-slot:activator="{ on, attrs }")
            //- v-btn(v-bind="attrs" v-on="on") Open dialog
        v-card.pa-5
            h2.mb-3.text-center.text-uppercase
                | Final verification
            .description
                p For data confidentiality, we automatically remove private content from your text, like phone numbers, email addresses, and dollar amounts.
                p Our algorithms can't catch everything, so please double-check the text below and remove any private info.
            .preview
                v-textarea.no-border.pa-0.pl-3.ma-0(:value="redactedText" rows="7" hide-details)

            .buttons.mt-3
                v-row(justify="space-between")
                    v-col(cols="4")
                        v-btn.hide(outlined color="secondary" @click="showSubmitTextValidation = false" block) Go back
                    v-col(cols="7" md="8" offset="1" offset-md="0").text-right
                        v-btn(depressed color="success" block @click="handleSubmit") Submit
                            v-icon(color="white").ml-1 mdi-check-circle

</template>

<script>
import { redactString } from '@/helpers'
import { sync } from 'vuex-pathify'
export default {
  name: 'SubmissionCheck',
  props: { text: { type: String, required: true } },
  data() {
    return {}
  },
  computed: {
    ...sync({ showSubmitTextValidation: 'dialogs/showSubmitTextValidation' }),
    redactedText() {
      return this.text && this.text.length > 0 ? redactString(this.text) : ''
    }
  },
  methods: {
    handleSubmit() {
      this.showSubmitTextValidation = false
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.preview {
  cursor: default;
  user-select: none;
  border: 2px solid $success;
  white-space: break-spaces;
  font-weight: bold;
}

.preview ::v-deep p {
  margin-bottom: 2rem;
}

.no-border.v-text-field ::v-deep  textarea {
cursor: default;
user-select: none !important;
}
.no-border.v-text-field ::v-deep > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.no-border.v-text-field ::v-deep > .v-input__control > .v-input__slot:after {
  border-style: none;
}

.description{
    font-size:0.9rem;
}
</style>
