<template lang="pug">
    v-menu.bottom-nav-menu(top offset-y)
        template(v-slot:activator="{ on, attrs }")
            v-btn(v-on="on" v-bind="attrs")
                span {{text}}
                v-icon.mr-2 {{icon}}
        v-list
            v-list-item(v-for="item in items", :key="item.text" :to="item.link")
                v-icon.mr-3 {{item.icon}}
                | {{item.text}}
</template>

<script>
export default {
  name: 'BottomNavMenu',
  props: {
    link: { type: String, required: true },
    text: { type: String, required: true },
    icon: { type: String, required: true }
  },
  data() {
    return {
      items: [
        { text: 'All Submissions', link: '/inbox/all_submissions', icon: 'mdi-expand-all' },
        { text: 'Feedback Ready', link: '/inbox/completed', icon: 'mdi-inbox-arrow-down' },
        { text: 'Waiting for Review', link: '/inbox/pending', icon: 'mdi-clock-outline' },
        { text: 'Archive', link: '/inbox/archived', icon: 'mdi-archive-arrow-down-outline' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
