
const md = require('markdown-it')({
  html: true,
  linkify: true,
  typographer: true
}).use(require('markdown-it-mark'))
md.linkify.set({ fuzzyEmail: false, set: { target: '_blank' } })

export const isValidEmail = str => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(String(str).toLowerCase())
}

export const isValidPassword = str => {
  const re = /^(?=.*[a-z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/
  return re.test(String(str))
}

export const scrollToTarget = (element, offset = 45) => {
  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })
}

export const copyString = (str, parentEl) => {
  const el = document.createElement('textarea')
  el.value = str.replace(/<br>/g, '\n')
  el.setAttribute('readonly', '') // Make it readonly to be tamper-proof
  el.style.position = 'absolute'
  el.style.left = '-9999px'

  const appendRoot = parentEl || document.body
  appendRoot.appendChild(el)
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false
  el.select()
  document.execCommand('copy')
  appendRoot.removeChild(el)
  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }
}

export const randomFromArray = (arr, num = 10) => {
  return arr.sort(() => 0.5 - Math.random()).slice(0, num)
}

export const chunkArray = (inputArray, itemsPerPage) => {
  return inputArray.reduce((all, one, i) => {
    const ch = Math.floor(i / itemsPerPage)
    all[ch] = [].concat(all[ch] || [], one)
    return all
  }, [])
}

export const waitForGlobalFunction = function(key, callback) {
  if (window[key]) {
    callback()
  } else {
    setTimeout(function() {
      waitForGlobalFunction(key, callback)
    }, 100)
  }
}

export const waitForElemToExist = selector => {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector))
        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  })
}

export const deepCopyObject = obj => {
  function isObject(obj) {
    var type = typeof obj
    return type === 'function' || (type === 'object' && !!obj)
  }
  function iterationCopy(src) {
    const target = {}
    for (const prop in src) {
      if (Object.prototype.hasOwnProperty.call(src, prop)) {
        // if the value is a nested object, recursively copy all it's properties
        if (isObject(src[prop])) {
          target[prop] = iterationCopy(src[prop])
        } else {
          target[prop] = src[prop]
        }
      }
    }
    return target
  }

  return iterationCopy(obj)
}

export const partOfSpeechColor = str => {
  if (!str || str.length < 1) return null

  const description = str.toLowerCase()
  if (description.includes('base form')) return 'olivedrab'
  if (description.includes('superlative adjective')) return 'hotpink'
  if (description.includes('superlative adverb')) return 'slategray'
  if (description.includes('pronoun')) return 'cornflowerblue'
  if (description.includes('proper noun')) return 'coral'
  if (description.includes('noun')) return 'dodgerblue'
  if (description.includes('adjective') || description.includes('adj')) {
    return 'darkslateblue'
  }
  if (description.includes('preposition')) return 'indianred'
  if (description.includes('determiner')) return 'orange'
  if (description.includes('interjection')) return 'purple'
  if (description.includes('foreign')) return 'maroon'
  if (description.includes('comparative')) return 'lime'
  if (description.includes('adverb')) return 'goldenrod'
  if (description.includes('verb')) return 'seagreen'
  if (description.includes('idiom')) return '#fd7014'

  return null
}

export const capitalize = (str, capitalizeAfterSpecialChars = false) => {
  if (!str || str.length < 1) return null

  const v1 = str[0].toUpperCase() + str.substr(1, str.length - 1)
  if (!capitalizeAfterSpecialChars) return v1

  const arr = v1.split('-')
  const combo = arr.map(itm => capitalize(itm))
  return combo.join('-')
}

export const sentenceCase = str => {
  if (!str || str.length < 1) return ''

  return str[0].toUpperCase() + str.substr(1, str.length - 1)
}

export const stripHtml = str => {
  const out = document.createElement('div')
  out.innerHTML = md.render(str)
  return stringToParagraphTags(out.textContent.trim())
}

export const stringToParagraphTags = (str) => {
  const string = '' + str
  const lines = string.split('\n').filter(Boolean)
  return lines.map((line) => {
    return '<p>' + line + '</p>'
  }).join('')
}

export const paragraphTagsToString = str => {
  return str.split('<p>').join('').split('</p>').join('\n\n').trim()
}

export const getUserIp = async (context, payload) => {
  try {
    // console.log('Getting user ip address..')
    const ipInfo = await fetch('https://www.cloudflare.com/cdn-cgi/trace')
    if (!ipInfo || !ipInfo.ok) {
      throw new Error('Could not get user IP Info for registration.')
    }
    const rawText = await ipInfo.text()

    if (!rawText || !rawText.includes('ip=')) {
      throw new Error('Could not get user IP Innfo for registration')
    }

    const ipAddressRaw = rawText.match(/ip=\d.*/gi, rawText)

    if (!ipAddressRaw || ipAddressRaw.length < 1) {
      throw new Error('Could not get user IP Innfo for registration')
    }
    const ipAddressClean = ipAddressRaw[0].split('ip=').join('')
    return ipAddressClean
  } catch (err) {
    console.error(err)
    return { error: err }
  }
}

export const redactString = str => {
  const redactEmails = (str) => {
    const searchRegExp = /\S+@\S+\.\S+/gi
    const replaceWith = ' [EMAIL ADDRESS REDACTED] '

    return str.replace(searchRegExp, replaceWith)
  }

  const redactMoney = (str) => {
    // eslint-disable-next-line no-useless-escape
    const searchRegExp = /[\$\£\€]\d+(?:[.,]\d+)?|\d+(?:[.,]\d+)?[\$\£\€]|(\d.*dollars)|(\d.*euro)/gi
    const replaceWith = ' [MONEY REDACTED] '

    return str.replace(searchRegExp, replaceWith)
  }

  const redactCreditCards = (str) => {
    const searchRegExp = /\b(?:\d{4}[ -]?){3}(?=\d{4}\b)/gi
    const replaceWith = ' [CREDIT CARD REDACTED] '

    return str.replace(searchRegExp, replaceWith)
  }

  const redactPhoneNumbers = (str) => {
    const searchRegExp = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})/gi
    const replaceWith = ' [PHONE NUBER REDACTED] '

    return str.replace(searchRegExp, replaceWith)
  }

  const withoutEmails = redactEmails(str)
  const withoutCreditCards = redactCreditCards(withoutEmails)
  const withoutPhoneNumbers = redactPhoneNumbers(withoutCreditCards)
  const withoutMoney = redactMoney(withoutPhoneNumbers)
  const res = withoutMoney

  return res
}
