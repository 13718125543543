<template lang="pug">
  .marked-down(:class="{'has-bg': hasBackground}")
    .formatted(v-html="postProcess(filteredText)" :class="{'font-weight-bold': bold}").pt-3
    v-btn(v-if="showReadMore && !expanded", outlined @click="expanded = true").my-3 Read more...
</template>

<script>
const md = require('markdown-it')({
  html: true,
  linkify: true,
  typographer: true
}).use(require('markdown-it-mark'))
md.linkify.set({ fuzzyEmail: false, set: { target: '_blank' } })

export default {
  name: 'MarkedDown',
  props: {
    text: { type: String, default: '' },
    bold: { type: Boolean, default: false },
    showReadMore: { type: Boolean, default: true },
    hasBackground: { type: Boolean, default: false }
  },
  data() {
    return {
      expanded: false
    }
  },
  created() {
    if (this.text && this.text.length < 75) this.expanded = true
  },
  computed: {
    filteredText() {
      if (!this.text || this.text.length < 1) return ''
      if (this.showReadMore && !this.expanded) {
        return this.text.substr(0, 75) + '...'
      } else return this.text
    }
  },
  methods: {
    postProcess(str) {
      return md.render(str)
    },
    postProcessLine(str) {
      return md.renderInline(str)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/colors.scss";
.marked-down {
  display: inline-block;
  border-radius: 2px;
  padding: 0.5rem;

  &.has-bg {
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background: rgb(242, 248, 255);
  }
}
.formatted {
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.015rem;
  line-height: 155%;
  text-shadow: 1px 1px rgba(255, 255, 255, 0.5);
}

.formatted ::v-deep pre {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  margin-left: -1rem;

  padding: 1rem;
  background: rgba(255,255,255,0.63);
  display:inline-block;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;

  code {
    background: rgba(0, 0, 0, 0);
    display: inline-block;
    font-size: 1.25rem;
    line-height: 170%;
    font-weight: normal;
    font-family: sans-serif;
    white-space: normal;
    border-left: 1px solid gray;
    padding-left: 0.75rem;
    color: grey;
    font-weight: normal;
  }
}
.formatted ::v-deep hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: 1px;
  border: none;
  border-top: 2px dashed rgb(220, 220, 220);
}
.formatted ::v-deep blockquote {
  margin-left: -0.15rem;
  border-left: 1px solid gray;
  padding-left: 0.75rem;
}

.formatted ::v-deep li {
  margin-left: 0.5rem;
  color: black;
}
.formatted ::v-deep ol,
.formatted ::v-deep ul {
  margin-bottom: 1.5rem;
}

.formatted.font-weight-bold ::v-deep li {
  font-weight: bold;
}

.formatted ::v-deep blockquote p {
  color: grey;
  font-weight: normal;
}
.formatted ::v-deep em {
  display: inline;
  border-bottom: 2px solid black;
  font-style: normal;
}

.formatted ::v-deep mark {
  background-color: $primary;
  color: white;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-weight: bold;
}

@media only screen and (min-width: 600px) {
  .formatted ::v-deep pre {
    font-size: inherit;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    margin-left:-1.5rem;
    line-height: 200%;
  }

  .formatted ::v-deep pre:first-child {
    margin-top: inherit;
  }
}
</style>
