import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import lazyVideo from './plugins/lazyVideo'

import { auth } from './firebase'
import titleMixin from './mixins/titleMixin'

Vue.config.productionTip = false
let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      lazyVideo,
      render: h => h(App)
      // mounted: () => { document.dispatchEvent(new Event('render-event')) }
    }).$mount('#vue-app')
  }
})

Vue.mixin(titleMixin)

window.debug = console.log
