<template lang="pug">
    .message-action-buttons
      //- pre {{userData}}
      v-row(dense justify="end" align='center')
        v-col(cols="2", md="2")
          template(v-if="submission.numReplies && submission.numReplies > 0")
              v-btn(icon small :color="submission.archived ? $vuetify.theme.themes.light.archived : 'secondary lighten-2'", :to="getLink({id: submission.id, userId: submission.userId, companyCode: submission.companyCode}) + '?section=replies'")
                v-icon(small) mdi-message-outline
                span {{submission.numReplies}}

        v-col(cols="2", md="10" v-if="showRightColumn")
          .d-flex.justify-end
            BtnArchive(v-if="submission.responded && !isEditor", :submission="submission")
            router-link.text-none(v-if="!submission.responded" :to="getLink({id: submission.id, userId: submission.userId, companyCode: submission.companyCode})")
              small Waiting...
</template>

<script>
import { get } from 'vuex-pathify'
import BtnArchive from '@/components/Incoming/Partials/BtnArchive.vue'
export default {
  name: 'MessageActionButtons',
  components: { BtnArchive },
  props: { submission: { type: Object, required: true } },
  computed: {
    ...get({ userData: 'user/userData', getLink: 'submissions/getLink' }),
    isEditor() {
      return this.userData && this.userData.isEditor
    },
    showRightColumn() {
      return !this.isEditor || !this.submission.responded
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
small {font-size:0.8rem;}
</style>
