<template lang="pug">
    #main(:class="{debug: isDev}")
      v-app(v-if="ready === true", :class="{static: $route.meta && $route.meta.isStatic}")
        AppBar(v-if="!isStaticPage && !isAdminPage").d-print-none
        .layout
          v-main
            router-view
        BottomNav(v-if="showBottomNav").d-print-none
</template>

<script>
import AppBar from '@/components/AppBar'
// import TopNav from '@/components/TopNav'
import BottomNav from '@/components/BottomNav'
import { call, get } from 'vuex-pathify'
export default {
  name: 'App',
  components: { AppBar, BottomNav },
  watch: {},
  data() {
    return { interval: null }
  },
  created() {
    if (this.company && this.company.id) {
      this.bindCompanyRef({ code: this.company.id })
      this.bindSubmissionsRef()
      this.updateLastUserVisit()
    }
  },
  mounted() {
    this.updateFontsBasedOnLanguage()
  },
  computed: {
    ...get({
      company: 'user/company',
      userData: 'user/userData',
      unreadGlobalReplies: 'replies/unreadGlobalReplies',
      lang: 'user/lang'
    }),
    ready() {
      return (
        this.$route &&
        this.$route.meta &&
        Object.hasOwnProperty.call(this.$route.meta, 'requiresAuth')
      )
    },
    isDev() {
      return window && !!window.webpackHotUpdate
    },
    isAdminPage() {
      if (!this.$route || !this.$route.path) return null

      return (
        this.$route.path.includes('/login') ||
        this.$route.path.includes('/register') ||
        this.$route.path.includes('/sign-up')
      )
    },
    isStaticPage() {
      if (!this.$route || !this.$route.path) return null

      return this.$route.meta && this.$route.meta.isStatic
    },
    showTopNav() {
      return !this.isAdminPage && this.$vuetify.breakpoint.mdAndUp
    },
    showBottomNav() {
      return (
        !this.isAdminPage &&
        !this.isStaticPage &&
        this.$vuetify.breakpoint.smAndDown
      )
    }
  },
  methods: {
    ...call({
      bindCompanyRef: 'user/bindCompanyRef',
      bindSubmissionsRef: 'submissions/bindSubmissionsRef',
      updateLastUserVisit: 'user/updateLastUserVisit',
      updateFontsBasedOnLanguage: 'user/updateFontsBasedOnLanguage'
    })
  }
}
</script>

<style lang="scss">
@import "@/assets/css/colors.scss";

body {
  font-size: 20px;
}

pre.debug {
  display: none;
}
.debug pre.debug {
  display: inherit;
}

html {
  scroll-behavior: smooth;
  color: #494a4b;
}

.my-24{
  margin-top:4rem !important;
  margin-bottom:4rem !important;
}

#app {
  min-height: 100vh;
}

.v-application {
  a.text-none {
    text-decoration: none;
  }
  .text-dark,
  a.text-dark {
    color: $textDark;
  }
}

.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}

@import "@/assets/css/static.scss";
@import "@/assets/css/dynamic.scss";

@media only screen and (min-width: 600px) {
.keep-together{
  white-space: nowrap;
}
}

.v-application
  .v-input--selection-controls
  .v-input--selection-controls__input:hover
  .v-input--selection-controls__ripple:before {
  background-color: transparent !important;
}
.v-bottom-navigation {
  height: 40px !important;
}

.v-bottom-navigation >>> .v-btn__content {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: constant(
    safe-area-inset-bottom
  ); /* move footer up on iPhone X - iOS 11.0 */
  margin-bottom: env(
    safe-area-inset-bottom
  ); /* move footer up on iPhone X - iOS 11.2 */
}

@media print {
  .v-main {
    padding: 0 !important;
    margin: auto;
  }

  .max-width {
    max-width: 100% !important;
    margin: auto;
  }

  .print-break-before {
    page-break-before: always;
  }
  .print-break-after {
    page-break-after: always;
  }
}
</style>
