<template lang="pug">
    .no-submissions-yet.text-center
            v-icon(size="5rem" :color="color").mb-5 mdi-border-none-variant
            p.mb-5.font-weight-bold There are no {{type === 'ALL_SUBMISSIONS' ? '' : type.toLowerCase().split('_').join(' ')}} submissions for you to review now.
            template(v-if="userType === 'user'")
              p.mb-7 You can start by writing some text and submitting it.
              v-btn(color="secondary" x-large to="/inbox/action/submit" depressed) Start now
</template>

<script>
import { call } from 'vuex-pathify'
export default {
  name: 'NoSubmissionsYet',
  props: { type: { type: String, required: true }, userType: { type: String, default: 'user' }, color: { type: String, default: 'secondary' } },
  computed: {
  },
  methods: {
    ...call({ showCompose: 'nav/showCompose' })
  }
}
</script>

<style scoped lang="scss">
p {
    font-size:1.5rem;
}
</style>
