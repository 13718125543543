<template lang="pug">
  v-app-bar.app-bar(app flat absolute color="#f7fcfc" v-if="userData && userData.firstName && company && company.credits").px-md-6
    .max-width
      .d-flex.align-center.justify-space-between.justify-md-end
        v-btn.brand.font-weight-bold(v-if="$vuetify.breakpoint.smAndDown", text color="secondary" to="/inbox/all_submissions" active-class="no-active").px-0 PROIT English
        v-tooltip(left)
          template(v-slot:activator="{ on }")
            v-btn(v-on="on" to="/inbox/action/profile" text color="secondary")
              v-icon.mr-1(small) mdi-account
              span.name {{userData.firstName}}
              span.editor.ml-1(v-if="userData.isEditor") (Editor)
          span.white--text {{company.credits.available}} credits available
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'AppBar',
  computed: {
    ...get({ userData: 'user/userData', company: 'user/company' })
  }
}
</script>

<style lang="scss" scoped>
.max-width {
  width:100%;
  max-width: 1600px;
}

.no-active:before {
    background-color: transparent;
}
</style>
