<template lang="pug">
    .max-width
        .compose
          DialogSubmissionCheck(:text="textForSubmission", @click="handleSubmit")
          h1.type.mb-md-6(:class="{'display-1': $vuetify.breakpoint.mdAndUp}")
            span.mr-1.font-weight-regular Compose
            v-icon.mx-1 mdi-chevron-double-right
            span.font-weight-bold {{title}}
          //- pre.debug readyToSubmit = {{readyToSubmit}}
          .card.mt-md-5
              v-textarea#textForSubmission(outlined counter rows="7", color="primary", :counter-value="wordCount" v-model="textForSubmission", :placeholder="`Dear Mr. Anderson, this is ${capitalize(userData.firstName)} from ${company.name}, and I'm writing because...`" @input="submissionError = null")
              Options
              v-expand-transition
                  v-banner#warning-banner(v-if="submissionError", v-html="submissionError" :style="{cursor: 'default'}").error.lighten-5.secondary--text.text--darken-4.text-center.pa-3.my-6
              BtnSubmit(:loading="loading", :ready="readyToSubmit", :textForSubmission="textForSubmission" @click="readyToSubmit ? showPreview() : null").mt-4
</template>

<script>
import DialogSubmissionCheck from '@/components/Incoming/Submit/DialogSubmissionCheck.vue'
import BtnSubmit from '@/components/Incoming/Submit/BtnSubmit.vue'
import { redactString } from '@/helpers'
import Options from '@/components/Incoming/Submit/Options.vue'
import { get, call, sync } from 'vuex-pathify'
export default {
  name: 'SubmitPage',
  components: { BtnSubmit, Options, DialogSubmissionCheck },
  data() {
    return {
      submissionError: null,
      textForSubmission: '',
      loading: false,
      title: 'Submit your text for native editing'
    }
  },
  created() {
    this.showSubmitTextValidation = false
  },
  mounted() {},
  computed: {
    ...sync({
      userData: 'user/userData',
      company: 'user/company',
      showSubmitTextValidation: 'dialogs/showSubmitTextValidation'
    }),
    ...get({
      maxNumWords: 'transactions/maxNumWords',
      calcWordLength: 'transactions/calcWordLength',
      calcCreditCost: 'transactions/calcCreditCost'
    }),
    readyToSubmit() {
      return (
        this.calcWordLength(this.textForSubmission) > 1 &&
        this.calcCreditCost({
          text: this.textForSubmission,
          returnWithLabel: false
        }) > 0
      )
    }
  },
  methods: {
    ...call({
      test: 'submissions/test',
      makeSubmission: 'submissions/makeSubmission'
    }),
    showPreview() {
      this.showSubmitTextValidation = true
    },
    capitalize(s) {
      return s && s[0].toUpperCase() + s.slice(1)
    },
    wordCount() {
      const count = this.calcWordLength(this.textForSubmission)
      const res = count === 1 ? count + ' word' : count + ' words'
      return 'Submitting ' + res
    },
    async handleSubmit() {
      const TEXT_MIN_LENGTH_ERROR =
        'Please ensure you have written at least a few words to submit.'
      const TEXT_MAX_LENGTH_ERROR =
        "This app is intended for quick feedback. It's better to split long text into mulitple separate submissions."
      const INSUFFICIENT_CREDITS_ERROR =
        'You do not have enough credits available. Please <a href="/inbox/action/profile">go to your profile</a> to upgrade.'

      try {
        this.loading = true
        if (this.textForSubmission && this.textForSubmission.length > 2) {
          const wordLength = this.calcWordLength(this.textForSubmission)
          if (wordLength < 2) throw new Error(TEXT_MIN_LENGTH_ERROR)

          // console.log('in submit.vue about to make submission')
          await this.makeSubmission({
            text: redactString(this.textForSubmission)
          })

          this.$router.push('/inbox/pending')
        } else {
          throw new Error(TEXT_MIN_LENGTH_ERROR)
        }
      } catch (err) {
        console.log('got error', err.toString())
        if (err.toString().includes('INSUFFICIENT_CREDITS')) {
          this.submissionError = INSUFFICIENT_CREDITS_ERROR
        } else if (err.toString().includes('TEXT_MAX_LENGTH_ERROR')) {
          this.submissionError = TEXT_MAX_LENGTH_ERROR
        } else {
          this.submissionError = TEXT_MIN_LENGTH_ERROR
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
h1 {
  cursor: default;
  font-size: 1.5rem;
}

.card {
  // border: 2px dashed rgb(194, 194, 194);
  padding-top: 2rem;
  width: 100%;
}

::v-deep #textForSubmission {
  font-size: 1.5rem;
  color: $secondary;
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: inherit;
  }
}
</style>
