import { db } from '@/firebase.js'
import { make } from 'vuex-pathify'
import sortBy from 'lodash.sortby'

const state = {
  submission: {},
  submissions: [],
  loadingSubmission: false
}

const getters = {
  ...make.getters(state),
  completed: state => {
    const arr = state.submissions.filter(
      submission => submission.responded && !submission.archived
    )
    return [
      ...sortBy(arr, itm => (itm.created ? itm.created.toMillis() : ''))
    ].reverse()
  },
  pending: state => {
    const arr = state.submissions.filter(submission => !submission.responded)
    return [
      ...sortBy(arr, itm => (itm.created ? itm.created.toMillis() : ''))
    ].reverse()
  },
  archived: state => {
    const arr = state.submissions.filter(submission => submission.archived)
    return [
      ...sortBy(arr, itm => (itm.created ? itm.created.toMillis() : ''))
    ].reverse()
  },
  allSubmissionsOrderedByDate: state => {
    return [
      ...sortBy(state.submissions, itm =>
        itm.created ? itm.created.toMillis() : ''
      )
    ].reverse()
  },
  recentlyReviewed: state => {
    const arr = state.submissions.filter(
      submission => submission.respondedTime
    )

    return [...sortBy(arr, itm => itm.created.toMillis())].reverse()
  },
  getLink: (state, getter, rootState, rootGetters) => payload => {
    const userData = rootGetters['user/userData']
    const isEditor = userData.isEditor

    return `/inbox/messages/${isEditor ? 'evaluate' : 'review'}/${
      payload.companyCode
    }/${payload.userId}/${payload.id}`
  },
  calcSubmissionStatus: state => payload => {
    const { submission } = payload

    if (!submission.responded) return 'pending'
    else if (submission.archived) return 'archived'
    else return 'completed'
  },
  calcSubmissionStatusIndicator: (state, getters) => payload => {
    const { submission } = payload
    const submissionStatus = getters.calcSubmissionStatus({ submission })

    if (submissionStatus === 'completed') {
      return 'mdi-circle-medium'
    } else if (submissionStatus === 'archived') {
      return 'mdi-rhombus-medium'
    } else {
      return 'mdi-square-medium'
    }
  }
}

const mutations = {
  ...make.mutations(state)
}

const actions = {
  ...make.actions(['submission']),
  resetSubmissions: async ({ commit }, payload) => {
    commit('SET_SUBMISSION', {})
    commit('SET_SUBMISSIONS', [])
  },
  makeSubmission: async ({ dispatch, rootGetters, rootState }, payload) => {
    const { text } = payload
    const { id: companyCode, credits } = rootGetters['user/company']
    const { id: userId } = rootGetters['user/userData']

    const options = {
      ...rootState.options,
      tags: rootState.options.tags.map(tag => tag.text)
    }

    const creditCost = rootGetters['transactions/calcCreditCost']({ text })

    if (!creditCost || creditCost < 0) throw new Error('TEXT_MAX_LENGTH_ERROR')

    if (!credits || credits.available < creditCost) {
      throw new Error('INSUFFICIENT_CREDITS')
    }

    const submission = {
      text,
      options,
      created: new Date(),
      companyCode,
      userId,
      responded: false,
      creditCost
    }

    if (submission.text && submission.text.length > 2) {
      const doc = db
        .collection('submissions')
        .doc(companyCode)
        .collection(userId)
        .doc()

      await doc.set(submission)
    }
  },
  test: async ({ dispatch, rootGetters }, payload) => {
    console.log('About to get all submissions')
    const allSubmissionsInLedgerRef = await db
      .collection('submissionsLedger')
      .get()
    const allSubmissionsInLedger = allSubmissionsInLedgerRef.docs.map(doc =>
      doc.data()
    )

    const allSubmissions = await Promise.all(
      allSubmissionsInLedger.map(async itm => {
        const submissionRef = await db
          .collection('submissions')
          .doc(itm.companyCode)
          .collection(itm.userId)
          .doc(itm.id)
          .get()
        const doc = submissionRef.data()
        return doc
      })
    )

    console.log('all submissions = ', allSubmissions)
  },
  bindSubmission: async ({ commit, dispatch }, payload) => {
    console.log('when binding submission, payload= ', payload)
    commit('SET_LOADING_SUBMISSION', true)

    const { submissionId, userId, companyCode } = payload
    // console.log('In store, about to set submission for id', id)

    // console.log(
    //   'About to bind submission with companyCode',
    //   companyCode,
    //   'for userId',
    //   userId,
    //   'and submission id:',
    //   submissionId
    // )

    const query = db
      .collection('submissions')
      .doc(companyCode)
      .collection(userId)
      .doc(submissionId)

    query.onSnapshot(
      querySnapshot => {
        const obj = querySnapshot.data()
        const id = querySnapshot.id
        commit('SET_SUBMISSION', { id, ...obj })
        // dispatch('replies/bindRepliesForSubmissionId', { submissionId, userId, companyCode }, { root: true })
        commit('SET_LOADING_SUBMISSION', false)
      },
      err => {
        console.log(`Encountered error: ${err}`)
        commit('SET_LOADING_SUBMISSION', false)
      }
    )
  },

  setArchived: async ({ getters, dispatch }, payload) => {
    const { userId, companyCode, id, archived } = payload

    console.log(
      'About to set submission archived with companyCode, userId, id',
      companyCode,
      userId,
      id
    )

    const wasArchived = archived && archived === true

    await db
      .collection('submissions')
      .doc(companyCode)
      .collection(userId)
      .doc(id)
      .update({
        archived: !wasArchived
      })

    await db
      .collection('submissionsLedger')
      .doc(id)
      .update({
        status: wasArchived ? 'COMPLETED' : 'ARCHIVED'
      })
  },
  async bindSubmissionsRef({ rootGetters, commit }, payload) {
    /*
    There are 2 kinds of submission listings
      1. standard user submission listings: these go /submissions/{companyCode}/{userId}/{submissionId}
      2. all submission listings for all users. Since they're nested, we keep a ledger of them i /submissionsLedger
          - Step 1: we get all ids from that ledger
          - Step 2: we individually get() each submission from under its /submissions/{companyCode}/{userId}/{submissionId}
          - Step 3: we bind that to the query, so it gets updated in real time on changes
      3. This pre-supposes that makeSubmission() not only creates a submission under /submissions/{companyCode}/{userId}/{submissionId},
          but also writes it into the ledger
      4. And also that when a submission is marked with Feedback or changes, the ledger gets updated to match.
    */

    const { id: companyCode } = rootGetters['user/company']
    const { id: userId } = rootGetters['user/userData']

    if (rootGetters['user/userData'].isEditor) {
      const d = new Date()
      const daysAgo = new Date(d.setDate(d.getDate() - 30))

      const query = db
        .collection('submissionsLedger')
        .where('created', '>=', daysAgo)
        .orderBy('created', 'desc')

      query.onSnapshot(
        async querySnapshot => {
          const allSubmissionsInLedger = querySnapshot.docs.map(doc => {
            return { id: doc.id, ...doc.data() }
          })

          const allSubmissions = await Promise.all(
            allSubmissionsInLedger.map(async itm => {
              const submissionRef = await db
                .collection('submissions')
                .doc(itm.companyCode)
                .collection(itm.userId)
                .doc(itm.id)
                .get()
              const doc = submissionRef.data()
              return { id: itm.id, ...doc }
            })
          )
          commit('SET_SUBMISSIONS', allSubmissions)
        },
        err => {
          console.log(`Encountered error: ${err}`)
        }
      )
    } else {
      const query = db
        .collection('submissions')
        .doc(companyCode)
        .collection(userId)
        .where('userId', '==', rootGetters['user/userData'].id)

      query.onSnapshot(
        querySnapshot => {
          const obj = querySnapshot.docs.map(doc => {
            return { id: doc.id, ...doc.data() }
          })
          commit('SET_SUBMISSIONS', obj)
        },
        err => {
          console.log(`Encountered error: ${err}`)
        }
      )
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
