<template lang="pug">
    .static-page
        template(v-if="loading")
            v-skeleton-loader(type="article" v-for="n in 10", :key="n")
        template(v-else)
            MarkedDown(:text="text" :showReadMore="false")
</template>

<script>
import { get } from 'vuex-pathify'
import MarkedDown from '@/components/MarkedDown.vue'
export default {
  name: 'StaticPage',
  components: { MarkedDown },
  data() {
    return {
      loading: true,
      path: '',
      text: ''
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...get({ activePageId: 'nav/activePageId' })
  },
  methods: {
    async  init() {
      const path = `/data/${this.activePageId}.md`
      const res = await fetch(path)
      this.text = await res.text()
      this.loading = false
    }
  }
}
</script>
