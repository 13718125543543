<template lang="pug">
    .submission-ratings
        Rating(v-for="item, idx in items" :key="item.title", :title="item.title", :type="item.type", :readOnly="readOnly", @input="$emit('input', $event)")

</template>

<script>
import { get, call } from 'vuex-pathify'
import Rating from '@/components/Incoming/Partials/Rating.vue'

export default {
  name: 'SubmissionRatings',
  components: { Rating },
  props: {
    ratings: { type: Object, required: true },
    readOnly: { type: Boolean, default: true }
  },
  data() {
    return {
      loading: false,
      submissionError: '',
      items: [
        { title: 'Grammar', type: 'grammar' },
        { title: 'Meaning is clear', type: 'isClear' },
        { title: 'Content/vocabulary is precise', type: 'vocabulary' },
        { title: 'Idioms are appropriate', type: 'appropriateIdioms' },
        { title: 'Tone is appropriate', type: 'appropriateTone' }
      ]
    }
  },
  computed: {
    ...get({})
  },
  methods: {
    ...call({})
  }
}
</script>

<style scoped>
.submission-ratings {
  max-width: 600px;
}
</style>
