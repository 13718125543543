<template lang="pug">
    .submit-button
        v-btn#btnSubmit(block depressed :outlined="!ready" :color="ready ? 'success lighten-1' : 'secondary lighten-5'", x-large, :loading="loading", :disabled="loading", @click="$emit('click')").font-weight-bold
            | {{text}}
            v-expand-transition
                v-icon.ml-2(v-if="ready" :color="isPremium ? 'yellow lighten-3' : null") mdi-dots-horizontal-circle
                v-icon.ml-2(v-else) mdi-arrow-up
        .text-center
          .credit-cost.text-center.secondary--text.mt-3.font-weight-bold.d-inline-block(:class="{'yellow lighten-4': isPremium}") Uses {{ calcCreditCost({text: textForSubmission, returnWithLabel: true}) }}
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'ButtonSubmit',
  props: {
    loading: { type: Boolean, default: false },
    textForSubmission: { type: String, required: true },
    ready: { type: Boolean, default: false }
  },
  computed: {
    ...get({
      calcCreditCost: 'transactions/calcCreditCost',
      isPremium: 'options/isPremium'
    }),
    text() {
      if (this.cost < 1) return 'Text is too long'
      return this.ready ? 'Submit for review' : 'Type some text above'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
