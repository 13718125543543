import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBdrulMy36--Iv8Sscz53BOqTkVA2neaQI',
  authDomain: 'proitenglish.firebaseapp.com',
  databaseURL: 'https://proitenglish.firebaseio.com',
  projectId: 'proitenglish',
  storageBucket: 'proitenglish.appspot.com',
  messagingSenderId: '918911391873',
  appId: '1:918911391873:web:a82baf4210c3e2556f1580'
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()

// collection references
const usersCollection = db.collection('users')

// export utils/refs
export { db, auth, storage, usersCollection }
