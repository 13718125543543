import router from '@/router'
import { make } from 'vuex-pathify'

import Compose from '@/components/Incoming/Compose.vue'
import MessageList from '@/components/Incoming/MessageList/MessageList.vue'
import Usage from '@/components/Incoming/Usage.vue'
import Review from '@/components/Incoming/Messages/Review.vue'
import Evaluate from '@/components/Incoming/Messages/Evaluate.vue'
import Page from '@/components/Incoming/Pages/Static.vue'

const state = { activeItem: '', activeComponent: Compose, activePageId: null, readyState: { masthead: false } }
const getters = {
  ...make.getters(state)

}

const mutations = make.mutations(state)

const actions = {
  ...make.actions('activeItem'),
  showCompose: async ({ commit }, payload) => {
    commit('SET_ACTIVE_COMPONENT', { type: 'COMPOSE', component: Compose })
  },
  showUsage: async ({ commit }, payload) => {
    commit('SET_ACTIVE_COMPONENT', { type: 'USAGE', component: Usage })
  },
  showReview: async ({ dispatch, commit }) => {
    commit('SET_ACTIVE_COMPONENT', { type: 'REVIEW', component: Review })
  },
  showEvaluate: async ({ dispatch, commit }) => {
    commit('SET_ACTIVE_COMPONENT', { type: 'EVALUATE', component: Evaluate })
  },
  showPage: async ({ dispatch, commit }, payload) => {
    const { id } = payload
    commit('SET_ACTIVE_PAGE_ID', id)
    commit('SET_ACTIVE_COMPONENT', { type: 'PAGE', component: Page })
  },
  showMessageList: async ({ commit }, payload) => {
    const { type } = payload
    commit('SET_ACTIVE_COMPONENT', { type, component: MessageList })
  },
  navTo: async ({ state, getters, dispatch }, payload) => {
    window.scrollTo(0, 1)

    // console.log('router pushing to', payload)
    if (router.currentRoute.path !== payload) {
      await router.push(payload)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
