<template lang="pug">
    .results.p-relative
        .mb-10
            h2.mb-4 They submitted:
            SubmissionReadOnly(:text="submission.text").mb-4
            TagsReadOnly(:tags="submission.options.tags || 'None provided'")

        .mb-5
            h2.mb-4 Their explanation:
            CommentsReadOnly(:text="submission.options.comments || 'None provided'")

        .mb-5(v-if="!submission.responded")
            h2.mb-4 Adjust or reformat their text?
            AdjustSubmittedText(:text="theirTextPreAdjustedByReviewer || 'None provided'", @change="theirTextPreAdjustedByReviewer = $event")

        v-divider.my-6

        template(v-if="submission.responded")
          .mb-5(v-if="submission.ratings && submission.linesAfterProofreading")
              h2.mb-4 Our Feedback
              //- Feedback(:arrComments="submission.ratings.comments", :adjustedText="theirTextPreAdjustedByReviewer")
              Feedback(:linesAfterProofreading="submission.linesAfterProofreading", :adjustedText="submission.adjustedText")

          .mt-8(v-if="submission.ratings && submission.ratings.grammar")
              h2.mb-4 Overall Rating Scores
              Ratings(:ratings="submission.ratings")

        template(v-else)
          .mb-5
              h2.mb-4  Your Feedback
              p.mt-3.mb-6
                strong.mr-2 Instructions:
                | Under each line, re-write their text with a better version.

              LineByLineReply(:lines="feedbackStringToArray({text: theirTextPreAdjustedByReviewer})" @change="arrReply = $event")
          .my-12

          .mb-5
              h2.mb-4  Your Reply Preview
              .preview
                template(v-if="arrReply && arrReply.length > 0")
                  //- pre.debug theirTextPreAdjustedByReviewer = {{theirTextPreAdjustedByReviewer}}
                  //- pre.debug originalLines = {{originalLines}}
                  pre.debug arrReply = {{arrReply}}
                  DiffedLines(:originalLines="feedbackStringToArray({text: theirTextPreAdjustedByReviewer})", :newLines="arrReply")

                template(v-else)
                  p You haven't replied to any text yet.

          .mb-5
              h2.mb-4  Your Ratings
              Ratings(:ratings="replyRatings" :readOnly="false", @input="$set(replyRatings, $event.type, $event.value)")

          .submit-area.success.lighten-5.pa-3.mt-5
            v-btn(:x-large="$vuetify.breakpoint.mdAndUp" depressed block color="success" :disabled="!arrReply || arrReply.length < feedbackStringToArray({text: theirTextPreAdjustedByReviewer}).length || !allRatingAreCompleted({ratings: replyRatings})", @click="handleSubmitFeedback").font-weight-bold
              span(v-if="$vuetify.breakpoint.mdAndUp") Submit your evaluation
              span(v-else) Submit evaluation
              v-icon.ml-1 mdi-check-circle

</template>

<script>
import { get, call } from 'vuex-pathify'
import DiffedLines from '@/components/Incoming/Messages/Review/DiffedLines.vue'
import SubmissionReadOnly from '@/components/Incoming/Messages/SubmissionReadOnly.vue'
import CommentsReadOnly from '@/components/Incoming/Messages/CommentsReadOnly.vue'
import TagsReadOnly from '@/components/Incoming/Messages/TagsReadOnly.vue'
import AdjustSubmittedText from '@/components/Incoming/Messages/Evaluation/AdjustSubmittedText.vue'
import LineByLineReply from '@/components/Incoming/Messages/Evaluation/LineByLineReply.vue'
import Feedback from '@/components/Incoming/Messages/Feedback.vue'
import Ratings from '@/components/Incoming/Messages/Ratings.vue'
export default {
  name: 'EvaluationForm',
  components: {
    SubmissionReadOnly,
    CommentsReadOnly,
    TagsReadOnly,
    Ratings,
    Feedback,
    AdjustSubmittedText,
    LineByLineReply,
    DiffedLines
  },
  props: { submission: { type: Object, required: true } },
  data() {
    return {
      arrReply: [],
      replyRatings: {},
      theirTextPreAdjustedByReviewer: ''
    }
  },
  created() {
    this.theirTextPreAdjustedByReviewer = this.submission.adjustedText || this.submission.text
    this.arrReply = this.originalLines
  },
  computed: {
    ...get({
      allRatingAreCompleted: 'feedback/allRatingAreCompleted',
      feedbackStringToArray: 'feedback/feedbackStringToArray',
      arrResponsesToFeedbackString: 'feedback/arrResponsesToFeedbackString'
    }),
    originalLines() {
      if (!this.theirTextPreAdjustedByReviewer || this.theirTextPreAdjustedByReviewer.length < 1) return []
      return this.theirTextPreAdjustedByReviewer.split('\n').filter(Boolean)
    }
  },
  methods: {
    ...call({ submitFeedback: 'feedback/submitFeedback', navTo: 'nav/navTo' }),
    handleSubmitFeedback() {
      this.submitFeedback({ lines: this.arrReply, ratings: this.replyRatings, adjustedText: this.theirTextPreAdjustedByReviewer })
      this.navTo('/inbox/recently_reviewed')
    }
  }
}
</script>

<style scoped>
.text {
  font-size: 1.2rem;
}

.border-left {
  border-left: 1px dotted rgb(133, 133, 133);
  padding-left: 1rem;
}

.border-left-dark {
  padding-left: 2rem;
  border-left: 4px solid rgb(179, 179, 179);
}
</style>
