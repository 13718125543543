import Firebase from 'firebase/app'
// import 'firebase/auth'
import { db } from '@/firebase.js'
import router from '@/router'
import { make } from 'vuex-pathify'
// const { nanoid } = require('nanoid')

const state = {
  userData: {},
  company: {},
  lang: 'en'
}

const getters = make.getters(state)

const mutations = {
  ...make.mutations(state)
}

const actions = {
  ...make.actions(['company', 'userData']),
  createUser: async (context, { email, password }) => {
    return await Firebase.auth().createUserWithEmailAndPassword(
      email,
      password
    )
  },
  signUp: async (
    { dispatch, commit, getters },
    { company, firstName, email, password }
  ) => {
    if (company) {
      try {
        const obj = {
          company,
          firstName,
          email,
          password
        }
        return await db
          .collection('signups')
          .doc()
          .set(obj)
      } catch (err) {
        console.error('Create user error', err)
        throw new Error('COULD_NOT_CREATE_FIREBASE_USER')
      }
    }
  },
  register: async (
    { dispatch, commit, getters },
    { code, firstName, email, password }
  ) => {
    await dispatch('bindCompanyRef', { code })
    const company = getters.company

    if (company && company.name) {
      // About to register user

      await dispatch('createUser', {
        email,
        password,
        displayName: firstName
      })
        .then(async ({ user }) => {
          console.log('completed reg of user', user)

          const payload = {
            companyCode: code,
            id: user.uid,
            firstName,
            email,
            isEditor: false
          }

          await db
            .collection('users')
            .doc(user.uid)
            .set(payload)
        })
        .catch(err => {
          console.error('Create user error', err)
          throw new Error('COULD_NOT_CREATE_FIREBASE_USER')
        })
    } else {
      throw new Error('COMPANY_DOES_NOT_EXIST')
    }
  },
  signIn: async ({ dispatch, commit, getters }, { email, password }) => {
    try {
      return Firebase.auth()
        .signInWithEmailAndPassword(email, password)
        .then(async ({ user }) => {
          // console.log('Just did Firebase signin, with user = ', user)
          commit('SET_COMPANY', {})
          commit('SET_USER_DATA', {})

          await dispatch('bindUserData', { id: user.uid })
          await dispatch('bindCompanyRef', { code: getters.userData.companyCode })
          await dispatch('submissions/bindSubmissionsRef', {}, { root: true })
          await dispatch('onSignIn')
          return true
        })
        .catch(error => {
          const errorCode = error.code
          const errorMessage = error.message
          console.error(errorCode, errorMessage)
          throw new Error(errorMessage)
        })
    } catch (err) {
      throw new Error('SIGN_IN_FAILURE')
    }
  },
  async onSignIn({ state, getters }) {
    const id = getters.company.id

    await db
      .collection('companies')
      .doc(id)
      .update({
        lastSignIn: new Date()
      })
  },
  initCompany: async ({ dispatch }, payload) => {
    const { code } = payload

    const companyRef = await db
      .collection('companies')
      .doc(code)
      .get()
    const company = companyRef.data()
    await dispatch('setCompany', company)
  },
  signOut: async ({ dispatch }, payload) => {
    router.push('/login')
    Firebase.auth()
      .signOut()
      .then(async function() {
        await dispatch('setCompany', {})
        await dispatch('setUserData', {})
      })
      .catch(error => {
        console.error(error)
      })
  },
  updateLastUserVisit: async ({ dispatch }, payload) => {
    if (Firebase.auth().currentUser) {
      const userId = Firebase.auth().currentUser.uid
      db.collection('users')
        .doc(userId)
        .update({ lastVisit: new Date() })
    }
  },
  setLang: async ({ commit, dispatch }, payload) => {
    let lang = 'en'
    if (!payload.toLowerCase().startsWith('en')) lang = payload

    commit('SET_LANG', lang)
    dispatch('updateFontsBasedOnLanguage')

    const queryParams = new URLSearchParams(window.location.search)
    queryParams.set('lang', lang)
    history.replaceState(null, null, '?' + queryParams.toString())
  },
  updateSystemLanguage: async ({ dispatch }) => {
    console.log('router.currentRoute.query = ', router.currentRoute.query)
    let systemLang = 'en'
    if (router.currentRoute.params && router.currentRoute.params.lang) {
      console.log(1)
      systemLang = router.currentRoute.params.lang
    } else if (
      router.currentRoute.query &&
      router.currentRoute.query.lang &&
      router.currentRoute.query.lang.length > 0
    ) {
      console.log(2)
      systemLang = router.currentRoute.query.lang
    } else if (navigator && navigator.language) {
      console.log(3)
      systemLang = navigator.language
    }

    console.log('done updating system lang', systemLang)

    dispatch('setLang', systemLang)
  },
  updateFontsBasedOnLanguage: async ({ getters }) => {
    if (getters.lang === 'ko') {
      document.querySelector('body').classList.remove('medium-font')
      document.querySelector('body').classList.add('big-font')
    } else if (getters.lang === 'ru') {
      document.querySelector('body').classList.remove('big-font')
      document.querySelector('body').classList.add('medium-font')
    } else {
      document.querySelector('body').classList.remove('big-font')
      document.querySelector('body').classList.remove('medium-font')
    }
  },
  bindUserData: async ({ rootGetters, commit }, payload) => {
    return new Promise((resolve, reject) => {
      const query = db.collection('users').doc(payload.id)

      query.onSnapshot(
        querySnapshot => {
          const obj = querySnapshot
          commit('SET_USER_DATA', obj.data())
          resolve()
        },
        err => {
          console.log(`Encountered error: ${err}`)
          reject(err)
        }
      )
    })
  },
  bindCompanyRef: async ({ rootGetters, commit }, payload) => {
    return new Promise((resolve, reject) => {
      const query = db.collection('companies').doc(payload.code)

      query.onSnapshot(
        querySnapshot => {
          const obj = querySnapshot
          commit('SET_COMPANY', obj.data())
          resolve()
        },
        err => {
          console.log(`Encountered error: ${err}`)
          reject(err)
        }
      )
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
