<template lang="pug">
    .diffed-lines(:class="{'has-bg': hasBackground}")
      //- pre.debug diffs = {{diffs}}
      //- pre.debug newLines = {{newLines}}
      .formatted.pt-3
        p.line(v-for="diff, difIdx in diffs").mb-4
          span.word(v-for="snippet, snippetIdx in diff" :class="{removed: snippet.removed, added: snippet.added}" v-html="snippet.value")
</template>

<script>
import { diffWords } from 'diff'
export default {
  name: 'DiffedLines',
  props: {
    originalLines: { type: Array, required: true },
    newLines: { type: Array, required: true },
    hasBackground: { type: Boolean, default: true }
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  computed: {
    diffs() {
      const res = []
      for (let i = 0; i < this.originalLines.length; i++) {
        const originalLine = this.originalLines[i]
        const newLine = this.newLines[i] || originalLine
        res.push(diffWords(originalLine, newLine))
      }

      return res
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.diffed-lines {
  border-radius: 2px;
  padding: 0.5rem;

  &.has-bg {
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background: rgb(242, 248, 255);
  }
}
.formatted {
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.015rem;
  line-height: 155%;
  text-shadow: 1px 1px rgba(255, 255, 255, 0.5);
}
.word.removed{
  color:$archived;
  text-decoration: line-through;
  opacity:0.5;
  user-select: none;
}
.word.added{
  border: 1px solid rgb(213, 222, 233);
  background:rgb(235, 254, 216);
  text-shadow:1px 1px rgb(231, 247, 206);
  padding-left:0.2rem;
  padding-right:0.2rem;
  border-radius:5px;
  // font-weight:bold;
}
</style>
