<template lang="pug">
    .list-of-replies
        //- pre.debug sortedReplies = {{sortedReplies}}
        .reply(v-for="reply in sortedReplies", :key="reply.created.toDate().toString()", :reply-id="reply.id", v-intersect.once="onIntersect").mb-12
            .d-flex.flex-row.justify-space-between(:class="{'flex-row-reverse': isMe(reply)}")
                .meta.d-flex
                    .meOrThem.font-weight-bold.mr-2.mb-2 {{reply.userId === userData.id ? 'You wrote' : 'They wrote'}}
                    em.date {{creationTime(reply.created)}}

            .d-flex.flex-row.align-center(:class="{'flex-row-reverse': isMe(reply)}")
                v-card(outlined shaped :color="!isMe(reply) ? 'grey lighten-4' : 'grey lighten-4'" max-width="600px").pa-3
                    MarkedDown(:text="reply.text" :showReadMore="false" :has-background="false")
                    pre.debug reply.id = {{reply.id}}
                v-btn(v-if="isMe(reply)", icon color="secondary lighten-5" @click="deleteReply({id: reply.id})")
                    v-icon() mdi-close-octagon-outline

</template>

<script>
import { format } from 'timeago.js'
import { get, call } from 'vuex-pathify'
import sortBy from 'lodash.sortby'
import MarkedDown from '@/components/MarkedDown.vue'
export default {
  name: 'ListOfReplies',
  components: { MarkedDown },
  computed: {
    ...get({ replies: 'replies/replies', userData: 'user/userData' }),
    sortedReplies() {
      if (!this.replies || this.replies.length < 1) return []
      const sorted = sortBy(this.replies, 'created')
      return sorted
    }
  },
  methods: {
    ...call({ deleteReply: 'replies/deleteReply', markReplyWasRead: 'replies/markReplyWasRead' }),
    creationTime(time) {
      return format(time.toDate(), 'en_US')
    },
    isMe(reply) {
      return reply.userId === this.userData.id
    },
    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.$nextTick(() => {
          setTimeout(() => {
            const replyId = entries[0].target.getAttribute('reply-id')
            console.log('payload replyId = ', replyId)
            this.markReplyWasRead({ replyId })
          }, 2000)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
