<template lang="pug">
    .profile-page(v-if="company")
          h1.type.mb-md-6(:class="{'display-1': $vuetify.breakpoint.mdAndUp}")
            span.mr-1.font-weight-regular Profile
            v-icon.mx-1 mdi-chevron-double-right
            span.font-weight-bold {{title}}
          .max-width.mb-5.mx-auto
            v-row(align="center")
              v-col(cols="12" md="6")
                v-card(flat).pa-md-5.mx-auto
                    .grey.lighten-4.mx-auto
                          v-card( color="transparent" flat).pa-3.pa-md-5.mt-3.mx-auto
                              .name.capitalize.mb-6 Hiya {{userData.firstName}}! Here's your usage so far...
                              .d-flex.align-start.justify-space-between.mb-5
                                  b Credits Available
                                  .value.secondary--text {{company.credits.available}}
                              .d-flex.align-start.justify-space-between
                                  b Credits Used
                                  .value.secondary--text {{company.credits.used}}

                          v-card(color="transparent" flat).pa-5.mx-auto.text-center
                            .mt-6
                              v-btn(@click="signOut" block color="error" depressed large) Sign out

              v-col(cols="12" md="6")
                p.mt-6.mt-md-0.text-center.text-md-left
                  b You can get more credits
                UpgradeLevel(v-for="level in upgradeLevels", :level="level", :key="level.description").mb-3

          .success(v-if="$route.query && $route.query.status && $route.query.status === 'success'")
            v-alert(border="top" color="success" colored-border dismissible)
              .d-flex
                v-icon(color="success").mr-2 mdi-check
                .success--text.font-weight-bold Terrific! You've just purchased credits. Let's use them!

          .fail(v-if="$route.query && $route.query.status && $route.query.status === 'cancel'")
            v-alert(border="top" color="warning" colored-border dismissible)
              .d-flex
                v-icon(color="warning").mr-2 mdi-check
                .warning--text.font-weight-bold Your purchase was not completed. Did something go wrong? You can
                  a(href="mailto:support@proitenglish.com").orange--text.font-weight-bold.ml-1 email us at support@ProitEnglish.com.

          .max-width.mb-5.mx-auto
            ListOfOrders
</template>

<script>
import { get, call } from 'vuex-pathify'
import UpgradeLevel from '@/components/Incoming/Usage/UpgradeLevel.vue'
import ListOfOrders from '@/components/Incoming/Usage/ListOfOrders.vue'
export default {
  name: 'Profile',
  components: { UpgradeLevel, ListOfOrders },
  data() {
    return {
      title: 'Usage',
      upgradeLevels: [
        {
          description: 'Get 1 credit for 1 short text',
          button: { text: 'Buy 1 for $5' },
          stripeProductPriceId: 'price_1IEKGhGLNsCVinshXke2pguY',
          stripeProductPriceIdTest: 'price_1I9AsPGLNsCVinshXVNdWdA1'
        },
        {
          description: 'Get 10 credits for $4.70 each',
          button: { text: 'Buy 10 for $47' },
          savings: 'Save 6%',
          stripeProductPriceId: 'price_1IEKGIGLNsCVinshRM5NnUeG',
          stripeProductPriceIdTest: 'price_1IAIggGLNsCVinshFcvYF1V2'
        },
        {
          description: 'Get 20 credits for $4.50 each',
          button: { text: 'Buy 20 for $90' },
          savings: 'Save 10%',
          stripeProductPriceId: 'price_1IEKGbGLNsCVinshkAamodoj',
          stripeProductPriceIdTest: 'price_1I9B1HGLNsCVinshG6i5CQCo'
        },
        {
          description: 'Get 50 credits for $3.50 each',
          button: { text: 'Buy 50 for $175' },
          savings: 'Save 30%',
          stripeProductPriceId: 'price_1IEKGWGLNsCVinshUOkNtss0',
          stripeProductPriceIdTest: 'price_1I9B3GGLNsCVinshRJqE3QCT'
        },
        {
          description: 'Get 100 credits for $3.25 each',
          button: { text: 'Buy 100 for $325' },
          savings: 'Save 35%',
          stripeProductPriceId: 'price_1IEKGPGLNsCVinshPMv70bZf',
          stripeProductPriceIdTest: 'price_1I9B4kGLNsCVinshZbg2dX5y'
        }
      ]
    }
  },
  computed: {
    ...get({ userData: 'user/userData', company: 'user/company' })
  },
  mounted() {
    // this.initCompany({ code: this.company.id })
  },
  methods: {
    ...call({ initCompany: 'user/initCompany', signOut: 'user/signOut' })
  }
}
</script>

<style scoped>
@media only screen and (min-width: 600px) {
}
</style>
