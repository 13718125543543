<template lang="pug">
    v-card.message(v-if="submission", flat :class="['color-' + calcSubmissionStatus({submission}), {'is-premium': submission.options.isPremium}]").px-2.mb-4
        v-row(:dense="$vuetify.breakpoint.smAndDown" align="center" justify="space-between")
            v-col(cols="6", md="2")
              v-tooltip(left)
                template(v-slot:activator="{ on, attrs }")
                  v-btn(text small v-on="on", :color="calcSubmissionStatus({submission})", :to="getLink({id: submission.id, userId: submission.userId, companyCode: submission.companyCode})").text-regular.px-0
                    v-icon().mr-1 {{calcSubmissionStatusIndicator({submission})}}
                    span.font-weight-bold {{calcSubmissionStatus({submission})}}
                    v-icon(small v-if="submission.options && submission.options.isPremium").ml-2 mdi-star

                span.text-capitalize.white--text {{submission.options && submission.options.isPremium ? 'Express ' : 'Regular'}} {{calcSubmissionStatus({submission})}} submission
            v-col(cols="6" md="2").text-right.text-md-left
                .created.text-left.mr-3(:class="{'archived--text': submission.archived}" :title="submission.created.toDate().toLocaleString()") {{creationTime(submission.created)}}
            v-col(cols="12", md="6").px-8.px-md-0
                router-link.d-flex.align-center(:to="getLink({id: submission.id, userId: submission.userId, companyCode: submission.companyCode})", :title="submission.text")
                    .snippet {{submission.text ? submission.text.substr(0,85) : ''}}......

            v-col(cols="12" md="2").text-center
                MessageActionButtons(:submission="submission")

</template>

<script>
import { format } from 'timeago.js'
import { get } from 'vuex-pathify'
import MessageActionButtons from '@/components/Incoming/MessageActionButtons'
export default {
  name: 'MessageInList',
  components: { MessageActionButtons },
  props: { submission: { type: Object, required: true } },
  computed: {
    ...get({
      getLink: 'submissions/getLink',
      calcSubmissionStatus: 'submissions/calcSubmissionStatus',
      calcSubmissionStatusIndicator:
        'submissions/calcSubmissionStatusIndicator',
      activeComponent: 'nav/activeComponent'
    })
  },
  methods: {
    creationTime(time) {
      if (!time) return null
      return format(time.toDate(), 'en_US')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.message {
  border-bottom: 1px dashed rgb(238, 238, 238);
  // border-left: 3px solid transparent;
  background: rgb(244, 244, 244);
}

.message.is-premium {
  // border-left: 3px solid;
}
.message:last-child {
  border-bottom: none;
}
.message a {
  text-decoration: none;
}

.created {
  cursor: default;
}

.snippet, .created {
  color: $textDark;
  font-size: 0.8rem;
}

.message.is-premium.color-completed {
  border-color: $completed;
}

.message.is-premium.color-pending {
  border-color: $pending;
}
.message.is-premium.color-archived {
  border-color: $archived;
}

@media only screen and (min-width: 600px) {
  .message {
    background: transparent;
  }
}
</style>
