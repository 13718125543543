<template lang="pug">
    .submission-options.py-2.py-md-5.px-3.px-md-12
      h2.mb-5 Enhance your submission
      OptionPremiumCheck.mb-6
      OptionComments.mb-4
</template>

<script>
import OptionPremiumCheck from '@/components/Incoming/Submit/OptionPremiumCheck.vue'
import OptionComments from '@/components/Incoming/Submit/OptionComments.vue'
export default {
  name: 'SubmissionOptions',
  components: { OptionComments, OptionPremiumCheck },
  props: { },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.submission-options {
  background: #f5f5f5;
}
</style>
