<template lang="pug">
    .replies
      .max-width
        h2.mb-12.text-center Chat with us
        v-card(flat)
          ListOfReplies
          AddReply
</template>

<script>
import ListOfReplies from '@/components/Incoming/Replies/ListOfReplies'
import AddReply from '@/components/Incoming/Replies/AddReply'
export default {
  name: 'Replies',
  components: { AddReply, ListOfReplies }
}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 600px) {
  .max-width {
    max-width: 600px;
    margin-left: 0;
  }

  .replies .max-width {
    border-radius: 5px;
    padding: 2rem;
    box-shadow: 5px 5px 40px rgba(216, 216, 216, 0.2);
  }
}
</style>
