import { db } from '@/firebase.js'
import { make } from 'vuex-pathify'

const state = {}

const getters = {
  ...make.getters(state),
  allRatingAreCompleted: state => payload => {
    const { ratings } = payload
    if (!ratings) return false

    const {
      grammar,
      isClear,
      appropriateTone,
      vocabulary,
      appropriateIdioms
    } = ratings

    const res =
      grammar && isClear && appropriateTone && vocabulary && appropriateIdioms
    return !!res
  },

  feedbackStringToArray: state => payload => {
    const { text } = payload

    return text.split('\n').filter(Boolean)
  },

  arrResponsesToFeedbackString: state => payload => {
    const { text, responses } = payload

    // console.log('About to braid lines with text', text)
    // console.log('and responses', responses)

    const originalLines = text.split('\n').filter(Boolean)

    const res = originalLines.map((line, idx) => {
      return '```\n' + line + '\n```\n\n' + (responses[idx] || '') + '\n'
    })

    return res.join('\n')
  }
}

const mutations = {
  ...make.mutations(state)
}

const actions = {
  ...make.actions(['submission']),

  submitFeedback: async ({ getters, rootGetters, dispatch }, payload) => {
    const { lines, ratings, adjustedText } = payload

    const { userId, companyCode } = rootGetters['submissions/submission']
    const submissionId = rootGetters['submissions/submission'].id

    // const originalLinesInSubmission = getters.feedbackStringToArray({ text })

    console.log('about to submit adjusted text', adjustedText, 'with newline count', adjustedText.split('\n').length)

    const submissionUpdatePayload = {
      ratings,
      linesAfterProofreading: lines,
      adjustedText,
      responded: true,
      respondedTime: new Date()
    }
    await db
      .collection('submissions')
      .doc(companyCode)
      .collection(userId)
      .doc(submissionId)
      .update(submissionUpdatePayload)

    dispatch('submitFeedbackSubmittedNotificationToDb')

    await db
      .collection('submissionsLedger')
      .doc(submissionId)
      .update({ status: 'COMPLETED' })
  },
  submitFeedbackSubmittedNotificationToDb: async (
    { getters, rootGetters, dispatch },
    payload
  ) => {
    const submissionId = rootGetters['submissions/submission'].id
    const reviewer = rootGetters['user/userData'].id
    const learner = rootGetters['submissions/submission'].userId

    const feedback = {
      created: new Date(),
      id: submissionId,
      reviewer,
      learner
    }

    await db
      .collection('feedback')
      .doc(submissionId)
      .set(feedback)
  },
  onFeedbackReportOpened: async (
    { getters, rootGetters, dispatch },
    payload
  ) => {
    const { id } = payload
    const userData = rootGetters['user/userData']
    const isEditor = userData.isEditor

    if (!isEditor) {
      db.collection('feedback')
        .doc(id)
        .delete()
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
