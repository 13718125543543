<template lang="pug">
    .btn.mx-auto.d-flex.checkout-button(slot="checkout-button")
        slot(:openCheckoutForPlan="openCheckoutForPlan")
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  props: {},
  data: () => ({
    loading: false,
    successUrl: null,
    cancelUrl: null,
    customerEmail: null
  }),
  created() {
    this.init()
  },
  computed: {
    ...get({ userData: 'user/userData', company: 'user/company', stripe: 'stripe' })
  },
  methods: {
    async init() {
      this.customerEmail = this.userData.email
    },

    isDevMode() {
      return (
        (window && window.webpackHotUpdate) ||
        window.location.href.startsWith('http://localhost')
      )
    },
    async setCheckoutOptions(payload) {
      const baseUrl = this.isDevMode()
        ? 'http://localhost:8888'
        : 'https://proitenglish.com'

      this.successUrl = `${baseUrl}/inbox/action/profile?paid=true&status=success`
      this.cancelUrl = `${baseUrl}/inbox/action/profile?paid=false?status=cancel`

      // window.debug('Just set success url to', this.successUrl)
    },

    async openCheckoutForPlan(payload) {
      try {
        this.$emit('loading', true)
        // window.debug('About to open checkout for payload', payload)

        await this.setCheckoutOptions(payload)

        this.$nextTick(async () => {
          const apiUrl =
            document.location.origin + '/.netlify/functions/stripe-checkout'
          const stripePayload = {
            productPriceId: payload.stripeProductPriceId,
            email: this.customerEmail,
            stripeCustomerId: this.company.stripeCustomerId,
            dbUserId: this.userData.id,
            dbCompanyId: this.company.id,
            successUrl: this.successUrl,
            cancelUrl: this.cancelUrl,
            coupon: this.couponCode
          }

          // window.debug('sending stripe payload', stripePayload)
          const res = await fetch(apiUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(stripePayload)
          }).catch(err => {
            console.error(err)
            this.loading = false
          })

          // const json = await res.json()
          // window.debug('Got res', res)
          const txt = await res.text()
          // window.debug('Got txt', txt)
          const data = JSON.parse(txt)

          // window.debug('Got data', data)
          const checkoutId = data.checkoutId
          // window.debug('Got checkout id', checkoutId)

          if (checkoutId) {
            const stripe = window.Stripe(this.stripe.publishableKey)
            stripe
              .redirectToCheckout({
              // Make the id field from the Checkout Session creation API response
              // available to this file, so you can provide it as parameter here
              // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                sessionId: checkoutId

              })
              .then(function(result) {
                // window.debug('redirect result', result)
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer
              // using `result.error.message`.
              })
          } else {
            console.error('Invalid Stripe Checkout ID, so impossible to redirect.')
          }
        })
      } catch (err) {
        console.error(err)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  width: 100%;
}
</style>
