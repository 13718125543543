<template lang="pug">
    .message-actions
        v-row(justify="center", justify-md="space-between" align="center" dense)
            v-col(cols="12" md="3").text-center.text-md-left
                v-btn(small text @click="$router.go(-1)")
                    v-icon.mr-1(small) mdi-arrow-left
                    | Back to inbox
            v-col(cols="12", md="6").text-center.mt-4.mt-md-0
                .created {{submission.responded ? 'Reviewed' : 'Submitted'}} {{creationTime(submission.created)}} for {{submission.creditCost === 1 ? '1 credit' : submission.creditCost + ' credits'}}

            v-col(cols="12" md="3" v-if="$vuetify.breakpoint.mdAndUp").text-center.text-md-right
                template(v-if='submission.responded && isEditor')
                    BtnArchive(:submission="submission")

        v-divider.my-6

</template>

<script>
import { format } from 'timeago.js'
import BtnArchive from '@/components/Incoming/Partials/BtnArchive.vue'
export default {
  name: 'MessageActionButtons',
  props: { submission: { type: Object, required: true } },
  components: { BtnArchive },
  methods: {
    creationTime(time) {
      return format(time.toDate(), 'en_US')
    }
  },
  computed: {
    isEditor() {
      return this.userData && this.userData.isEditor
    }
  }
}
</script>
