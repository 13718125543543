import Vue from 'vue'
import Vuetify from 'vuetify/lib'

const vuetifyOpts = {
  rtl: false,
  theme: {
    // dark: true,
    themes: {
      dark: {
        primary: '#21CFF3',
        accent: '#FF4081',
        secondary: '#ffe18d',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252'
      },
      light: {
        primary: '#1976d2',
        accent: '#0F1E25',
        secondary: '#353536',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252',
        completed: '#038E59',
        pending: '#1976d2',
        archived: '#C799C7',
        profile: '#FF5252',
        allSubmissions: '#4A4A4A',
        textDark: '#212121'
      }
    }
  }
}

Vue.use(Vuetify)

export default new Vuetify(vuetifyOpts)
