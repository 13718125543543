import { make } from 'vuex-pathify'

const state = {
  comments: '',
  isPremium: false,
  tags: []
}

const getters = {
  ...make.getters(state)
}

const mutations = {
  ...make.mutations(state)
}

const actions = {
  ...make.actions(['submission'])

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
