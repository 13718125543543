<template lang="pug">
    .comments-to-submit
      //- v-switch(:value="value && value.length > 0", inset, label="Add your own explanation + context" hide-details @change="handleSwitch($event)").my-0

      //- pre.debug {{selectedTags}}
      //- pre.debug options tags = {{selectedTags}}
      .goals(v-if="!showComments")
        h2.mb-4 Your Goal (optional)
        v-chip(v-for="itm in tags[0].items" :outlined="!selectedTags.includes(itm)", :key="itm.value" :color="calcColor(itm, 'accent')" @click="toggleTag(itm)").ma-2.ml-0 {{itm.text}}
        br
        v-btn(color="secondary" depressed v-if="!showComments" @click="showComments = true").mt-3 Add more context (optional)

      v-expand-transition
        v-card.my-6(outlined color="transparent", v-if="showComments")
            h2.mb-4 Optional explanation
            p Select any tags that help explain this message
            .type(v-for="typeOfTag in tags", :key="typeOfTag.type")
              v-subheader.pl-0 {{typeOfTag.label}}
              v-chip(v-for="itm in typeOfTag.items" :outlined="!selectedTags.includes(itm)" :key="itm.value", :color="calcColor(itm, typeOfTag.color)" @click="toggleTag(itm)").my-2.mx-2 {{itm.text}}

            v-subheader.pl-0 More Info
            v-textarea(rows="3", solo, hide-details, v-model="comments", placeholder="This is an email I'm writing to our client, and the key point I want to communicate to him is...").grey.lighten-5
</template>

<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'CommentsToSubmit',
  props: { value: { type: String, default: '' } },
  data() {
    return {
      showComments: false,
      tags: [
        {
          type: 'goal',
          label: 'Goal',
          color: 'accent',
          items: [
            { value: 'sell', text: 'To Sell', negates: ['apologize', 'teach', 'dismiss'] },
            { value: 'request_info', text: 'To Request Info', negates: ['teach', 'dismiss', 'apologize'] },
            { value: 'explain', text: 'To Explain', negates: ['teach', 'request_info'] },
            { value: 'apologize', text: 'To Say Sorry', negates: ['sell', 'request_info', 'joking', 'excited', 'apologetic', 'ask_a_favor'] },
            { value: 'teach', text: 'To Teach Something', negates: ['explain', 'request_info', 'sell', 'dismiss'] },
            { value: 'dismiss', text: 'To Refuse/Dismiss', negates: ['sell', 'request_info', 'teach', 'ask_a_favor'] },
            { value: 'ask_a_favor', text: 'To Ask a Favor', negates: ['dismiss', 'apologize', 'teach'] }
          ]
        },
        {
          type: 'tone',
          label: 'Tone',
          color: 'accent',
          items: [
            { value: 'professional', text: 'Professional', negates: ['casual'] },
            { value: 'casual', text: 'Casual', negates: ['professional'] },
            { value: 'joking', text: 'Joking', negates: ['apologetic'] },
            { value: 'apologetic', text: 'Apologetic', negates: ['joking', 'excited', 'apologize'] },
            { value: 'excited', text: 'Excited', negates: ['apologetic', 'apologize'] }
          ]
        },
        {
          type: 'recipient',
          label: 'Recipient',
          color: 'accent',
          items: [
            { value: 'possible_customer', text: 'Possible Customer', negates: ['existing_customer', 'my_boss', 'coworker', 'subordinate'] },
            { value: 'existing_customer', text: 'Existing Customer', negates: ['possible_customer', 'my_boss', 'coworker', 'subordinate'] },
            { value: 'executive_vip', text: 'Executive/VIP', negates: ['coworker', 'subordinate'] },
            { value: 'my_boss', text: 'My Boss', negates: ['possible_customer', 'existing_customer', 'coworker', 'partner_company', 'subordinate'] },
            { value: 'coworker', text: 'Coworker', negates: ['my_boss', 'possible_customer', 'existing_customer', 'partner_company', 'subordinate'] },
            { value: 'subordinate', text: 'Subordinate', negates: ['my_boss', 'possible_customer', 'existing_customer', 'coworker'] },
            { value: 'partner_company', text: 'Partner Company', negates: ['my_boss', 'subordinate', 'coworker'] }
          ]
        }
      ]
    }
  },
  computed: {
    ...sync({ selectedTags: 'options/tags', comments: 'options/comments' })
  },
  methods: {
    handleSwitch(e) {
      if (e) {
        this.showComments = true
      } else {
        this.$emit('change', '')
        this.showComments = false
      }
    },
    toggleTag(tag) {
      if (this.itemCanBeSelected(tag)) {
        if (this.selectedTags.includes(tag)) {
          this.$set(this.selectedTags)
          this.selectedTags = this.selectedTags.filter(itm => itm.value !== tag.value)
        } else {
          this.selectedTags.push(tag)
        }
      }
    },
    itemCanBeSelected(tag) {
      return !this.selectedTags.find(itm => itm.negates.includes(tag.value))
    },
    calcColor(tag, baseColor) {
      const canBeSelected = this.itemCanBeSelected(tag)

      if (!canBeSelected) return 'grey lighten-2'

      const itemIsSelected = this.selectedTags.find(itm => itm.value === tag.value)

      if (itemIsSelected) {
        return 'primary'
      } else {
        return baseColor + ' lighten-2'
      }
    }
  }
}
</script>
