<template lang="pug">
    .review-submission
        template(v-if="loadingSubmission")
          v-skeleton-loader(type="card" color="white")

        template(v-else)
            template(v-if="submission && submission.userId")
              ActionButtons(:submission="submission").d-print-none
              .px-md-6
                LetterGrade(v-if="submission.responded").d-print-none
                EvaluationResults(:submission="submission")

            template(v-else)
              v-btn(text @click="$router.go(-1)").d-print-none
                  v-icon.mr-1 mdi-arrow-left
                  | Back to inbox
              .text-center
                v-icon.ma-5(size="5rem" color="secondary") mdi-alert
                p.mb-5 Unfortunately, the submission you were trying to evaluate does not appear to exist?
                v-btn(color="black" dark @click="showCompose") Try submitting something

            Replies.mt-12
</template>

<script>
import { get, call } from 'vuex-pathify'
import { capitalize } from '@/helpers'
import ActionButtons from '@/components/Incoming/Messages/Actions/ActionButtons.vue'
import EvaluationResults from '@/components/Incoming/Messages/Results.vue'
import Replies from '@/components/Incoming/Replies/Replies'
const LetterGrade = () =>
  import('@/components/Incoming/Messages/Review/LetterGrade.vue')
export default {
  name: 'EvaluateSubmissions',
  components: { EvaluationResults, LetterGrade, ActionButtons, Replies },
  data() {
    return {
      id: null,
      initComplete: false
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...get({
      submission: 'submissions/submission',
      loadingSubmission: 'submissions/loadingSubmission'
    })
  },
  methods: {
    ...call({
      bindSubmission: 'submissions/bindSubmission',
      bindRepliesForSubmissionId: 'replies/bindRepliesForSubmissionId',
      showCompose: 'nav/showCompose',
      onFeedbackReportOpened: 'feedback/onFeedbackReportOpened'
    }),
    async init() {
      if (!this.initComplete) {
        console.log('Since this is the first attempt, proceeding')
        const { companyCode, userId, submissionId } = this.$route.params
        await this.bindSubmission({ companyCode, userId, submissionId })
        await this.bindRepliesForSubmissionId({
          companyCode,
          userId,
          submissionId
        })
        this.initComplete = true
      } else {
        console.log('already init, so skipping.')
      }
    },
    capitalize: str => capitalize(str)
  }
}
</script>

<style scoped lang="scss">
.max-width {
  max-width: 900px;
}

p {
  font-size: 1.5rem;
  line-height: 175%;
}
</style>
