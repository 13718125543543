<template lang="pug">
    .add-reply
        v-textarea(rows="4" outlined placeholder="Type your reply here" v-model="text" hide-details)
        v-btn(:disabled="loading || !text || text.length < 2", :loading="loading", outlined color="primary" :block="$vuetify.breakpoint.smAndDown", @click="handleSubmit").mt-4 {{button.text}}
</template>

<script>
import { call } from 'vuex-pathify'
export default {
  name: 'AddReply',
  data() {
    return {
      text: '',
      loading: false,
      button: {
        text: 'Submit your reply'
      }
    }
  },
  methods: {
    ...call({ submitReply: 'replies/submitReply' }),
    async handleSubmit() {
      try {
        if (this.text && this.text.length > 0) {
          // console.log('About to submit reply:', this.text)
          this.submitReply({ text: this.text })
          this.text = ''
          this.button.text = 'Reply submitted!'

          setTimeout(() => {
            this.button.text = 'Submit your reply'
          }, 2500)
        }
      } catch (err) {
        console.error(err)
      } finally { this.loading = false }
    }
  }
}
</script>

<style lang="scss" scoped>

.max-width {
  max-width: 700px;
  margin-left:0;
}
</style>
