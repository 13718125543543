<template lang="pug">
    v-card.upgrade-level.pa-3.pa-md-0(:outlined="$vuetify.breakpoint.smAndDown" :flat="$vuetify.breakpoint.mdAndUp")
        v-row(align="center" justify="start" :dense="$vuetify.breakpoint.smAndDown")
            v-col(cols="12" md="3")
                .savings.text-center.text-md-left(:class="{'success--text': level.savings, 'font-weight-bold' : level.savings && $vuetify.breakpoint.mdAndUp}")
                    small {{level.savings || 'No savings'}}
            v-col(cols="12" md="5")
              .text-center.text-md-left(:class="{'font-weight-bold': $vuetify.breakpoint.smAndDown}")
                small(v-html="level.description")
            v-col(cols="12" md="4")
                Checkout(v-slot="scope" @loading="loading = $event")
                    v-btn(depressed :small="$vuetify.breakpoint.mdAndUp" :color="level.savings ? 'success lighten-5' : 'grey lighten-5'" block, :loading="loading" @click="scope.openCheckoutForPlan({stripeProductPriceId: level.stripeProductPriceId})").black--text {{level.button.text}}

</template>

<script>
import Checkout from '@/components/Checkout.vue'
export default {
  name: 'UpgradeLevel',
  components: { Checkout },
  props: {
    level: {
      type: Object,
      required: true
    },
    stripeProductPriceId: {
      type: String,
      default: 'price_1I95MGGLNsCVinshSpyd2R9w'
    }
  },
  data() {
    return {
      loading: false
    }
  }
}
</script>

<style lang="scss" scoped>
.savings {
}
</style>
