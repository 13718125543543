<template lang="pug">
    .comments
      v-tabs(v-model="activeTab" :vertical="$vuetify.breakpoint.mdAndUp")
          v-tab(v-model="activeTab").d-print-none
            v-btn(text block) Review
          v-tab(v-model="activeTab").d-print-none
            v-btn(text block) Plain Text
          BtnCopy(v-if="$vuetify.breakpoint.mdAndUp", :text="paragraphTagsToString(finalTextOnly)" color="secondary" hideIcon block @click="handleCopyClicked").ml-8.ml-md-0.d-print-none

          v-tabs-items(v-model="activeTab" )
            v-tab-item(:transition="false" :reverse-transition="false")
              v-overlay(absolute v-if="showCopied")
                h1 Copied!
              DiffedLines(:originalLines="originalLines", :newLines="linesAfterProofreading")
              //- MarkedDown(v-if="arrComments && arrComments.length > 0", :text="arrResponsesToFeedbackString({text: adjustedText, responses: arrComments.map(comment=>comment.line)})" :showReadMore="false" has-background)
            v-tab-item(:transition="false" :reverse-transition="false")
              v-overlay(absolute v-if="showCopied")
                h1 Copied!
              MarkedDown(v-if="finalTextOnly && finalTextOnly.length > 0", :text="finalTextOnly" :showReadMore="false", plainText has-background)

      BtnCopy(v-if="$vuetify.breakpoint.smAndDown", :text="paragraphTagsToString(finalTextOnly)" color="secondary" bold block).mt-3

</template>

<script>
import { get, call } from 'vuex-pathify'
import DiffedLines from '@/components/Incoming/Messages/Review/DiffedLines.vue'
import MarkedDown from '@/components/MarkedDown.vue'
import BtnCopy from '@/components/BtnCopy'
import { stripHtml, paragraphTagsToString } from '@/helpers'
export default {
  name: 'Rating',
  components: { MarkedDown, BtnCopy, DiffedLines },
  props: {
    linesAfterProofreading: { type: Array, required: true },
    adjustedText: { type: String, required: true }
  },
  data() {
    return {
      activeTab: null,
      showCopied: false
    }
  },
  created() {},
  computed: {
    ...get({
      arrResponsesToFeedbackString: 'feedback/arrResponsesToFeedbackString'
    }),
    originalLines() {
      if (!this.adjustedText || this.adjustedText.length < 1) return []
      return this.adjustedText.split('\n').filter(Boolean)
    },
    finalTextOnly() {
      const asStr = this.linesAfterProofreading.join('\n\n')
      return stripHtml(asStr)
    }
  },
  methods: {
    ...call({
      setComments: 'submissions/setComments',
      navTo: 'nav/navTo'
    }),
    paragraphTagsToString: str => paragraphTagsToString(str),
    handleCopyClicked() {
      this.showCopied = true

      setTimeout(() => {
        this.showCopied = false
      }, 2000)
    }
  }
}
</script>

<style scoped lang="scss">
.min-height {
  height: 30vh;
}

::v-deep .v-tab .v-btn .v-btn__content {
  justify-content: start;
}
</style>
