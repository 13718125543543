// import Firebase from 'firebase/app'
// import { db } from '@/firebase.js'
import { make } from 'vuex-pathify'

const state = {
  maxNumWords: 220
}

const getters = {
  ...make.getters(state),
  calcWordLength: state => payload => {
    if (!payload || payload.length < 1) return 0
    //  eslint-disable-next-line
    var matches = payload.match(/[\w\d\’\'-]+/gi);
    return matches ? matches.length : 0
  },

  calcCreditCost: (state, getters, rootState, rootGetters) => payload => {
    const { text = '', returnWithLabel = false } = payload

    const isPremium = rootGetters['options/isPremium']
    const numWords = getters.calcWordLength(text)

    const thresholds = isPremium ? [0, 250, 250, 500, 500, 1000] : [250, 500, 500, 1000]

    if (numWords >= thresholds[thresholds.length - 1]) {
      return returnWithLabel ? 'too many credits' : -1
    }

    const closest = thresholds.find(threshold => threshold > numWords)
    const credits = thresholds.indexOf(closest) + 1

    const creditOrCredits = credits === 1 ? ' credit' : ' credits'
    const expresslabel = isPremium ? ' (EXPRESS)' : ''

    const withLabel = credits + creditOrCredits + expresslabel
    return returnWithLabel ? withLabel : credits
  }
}

const mutations = {
  ...make.mutations(state)
}

const actions = {
  ...make.actions([])
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
