<template lang="pug">
    .options.d-inline-block
      p(v-if="isPremium") Your proofreading results will arrive within <mark><b>1 hour</b></mark>
      p(v-else) Your proofreading results will arrive within <b>24 hours</b>
      .d-flex.align-start
        v-switch(v-model="isPremium", inset, label="60-minute EXPRESS Check" hide-details :disabled="!isBusinessHours").my-0.mb-4

        v-tooltip(right max-width="300px")
            template(v-slot:activator="{ on, attrs }")
                v-icon(:small="$vuetify.breakpoint.mdAndUp", v-bind="attrs", v-on="on").ml-2 mdi-help-circle
            span.white--text {{tooltip}}
</template>

<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'OptionWriteFeedback',
  props: { value: { type: Boolean, default: false } },
  data() {
    return {
      tooltipNormal: 'Get rapid results in under an hour by choosing our EXPRESS CHECK option. Note this costs double the credits, compared to our 24-hour regular check.',
      tooltipDisabled: 'You can request an express 60-minute check during regular business hours (Moscow Time).'
    }
  },
  created() {
  },
  computed: {
    ...sync({ isPremium: 'options/isPremium' }),
    isBusinessHours() {
      const now = new Date()
      const thisHour = now.getUTCHours()

      const isBusinessHours = thisHour >= 7 && thisHour <= 16
      return isBusinessHours
    },
    tooltip() {
      return this.isBusinessHours ? this.tooltipNormal : this.tooltipDisabled
    }
  }
}
</script>

<style lang="scss" scoped>
mark {
  background-color: #fff9c4 !important;
}
</style>
