<template lang="pug">
    .submission-read-only
        MarkedDown(:text="text" :showReadMore="false")
</template>

<script>
import MarkedDown from '@/components/MarkedDown.vue'

export default {
  name: 'SubmissionReadOnly',
  props: { text: { type: String, required: true } },
  components: { MarkedDown }
}
</script>
