<template lang="pug">
    .results
        .mb-10
            h2.mb-4 You submitted:
            SubmissionReadOnly(:text="submission.text")

        .mb-5
            h2.mb-4 Your explanation:
            CommentsReadOnly(:text="submission.options.comments || 'None provided'")
            TagsReadOnly(:tags="submission.options.tags || 'None provided'")

        v-divider.my-6

        template(v-if="submission.responded")
          .mb-10(v-if="submission.ratings && submission.linesAfterProofreading")
              h2.mb-4 Our Feedback
              Feedback(:linesAfterProofreading="submission.linesAfterProofreading", :adjustedText="submission.adjustedText")

          .mb-5(v-if="submission.ratings && submission.ratings.grammar").print-break-before
              h2.mb-4 Overall Rating Scores
              Ratings(:ratings="submission.ratings")

        template(v-else)
          v-icon(size="5rem" color="secondary").mb-2 mdi-comment-processing-outline
          h2.mb-4 We are now evaluating the text you submitted...
          p.text You'll receive a notification as soon as our report is ready for you to view.
</template>

<script>
import { get, call } from 'vuex-pathify'
import TagsReadOnly from '@/components/Incoming/Messages/TagsReadOnly.vue'
const SubmissionReadOnly = () => import('@/components/Incoming/Messages/SubmissionReadOnly.vue')
const CommentsReadOnly = () => import('@/components/Incoming/Messages/CommentsReadOnly.vue')
const Feedback = () => import('@/components/Incoming/Messages/Feedback.vue')
const Ratings = () => import('@/components/Incoming/Messages/Ratings.vue')
export default {
  name: 'Results',
  components: { SubmissionReadOnly, CommentsReadOnly, TagsReadOnly, Ratings, Feedback },
  props: { submission: { type: Object, required: true } },
  computed: {
    ...get({ })
  },
  methods: {
    ...call({ copyToClipboard: 'copyToClipboard' })
  }
}
</script>

<style scoped>
.text {
  font-size: 1.2rem;
}

.border-left {
  border-left: 1px dotted rgb(133, 133, 133);
  padding-left: 1rem;
}

.border-left-dark {
  padding-left: 2rem;
  border-left: 4px solid rgb(179, 179, 179);
}

</style>
