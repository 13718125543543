// import router from '@/router'
import { make } from 'vuex-pathify'

const state = { showSubmitTextValidation: false }
const getters = {
  ...make.getters(state)

}

const mutations = make.mutations(state)

const actions = {
  ...make.actions('showSubmitTextValidation')
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
