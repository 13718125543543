<template lang="pug">
    .btn-copy
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          v-btn(v-on="on", tile text  :block="block", :color="disabled ? 'grey lighten-1' : iconColor", :class="{'font-weight-bold': bold}", @click="handleCopy").px-0
              v-icon.mr-1(v-if="!hideIcon") {{icon}}
              | {{title}}
        .tooltip-text
          span(v-if="disabled").white--text To be able to copy text directly from here, you will need to enable the "enable copy/paste" option when creating your submission.
          span(v-else).white--text Copies this feedback without formatting, so you can paste it into an email or document.
</template>

<script>
import { call } from 'vuex-pathify'
export default {
  name: 'BtnCopy',
  props: {
    text: { type: String, required: true },
    color: { type: String, default: null },
    bold: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    hideIcon: { type: Boolean, default: false },
    block: { type: Boolean, default: false }
  },
  data() {
    return {
      title: 'Copy Text',
      icon: 'mdi-content-copy',
      iconColor: null
    }
  },
  created() {
    this.iconColor = this.color || 'success'
  },
  methods: {
    ...call({ copyToClipboard: 'copyToClipboard' }),
    handleCopy() {
      if (!this.disabled) {
        this.$emit('click')
        this.copyToClipboard(this.text)
        this.title = 'Copied!'
        this.icon = 'mdi-check-circle'
        this.iconColor = 'success'

        setTimeout(() => {
          this.title = 'Copy Text'
          this.icon = 'mdi-content-copy'
          this.iconColor = 'primary'
        }, 3000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip-text {
  max-width: 300px;
}
</style>
