<template lang="pug">
    .message-list.max-width
        h1.header.type.text-capitalize.mb-6(:class="['color--' + activeComponent.type.toLowerCase().split('_').join('-'), {'display-1': $vuetify.breakpoint.mdAndUp}]")
            span.mr-1.font-weight-regular Inbox
            v-icon.mx-1 mdi-chevron-double-right
            span.font-weight-bold.section {{title}}

        .text-right()
          v-btn.refresh(icon :loading="!initComplete" small text loading-color="red" @click='doRefresh' title="Refresh this inbox").font-weight-bold.mb-1
              v-icon(small).ml-1 mdi-reload

        template(v-if="initComplete")
          .messages(v-if="submissions && submissions.length > 0")
            MessageInList(v-for="submission in submissions", :key="submission.id", :submission="submission")
          NoSubmissionsYet(v-else, :type="type", :userType="userData.isEditor ? 'editor' : 'user'", :color="activeComponent.type.toLowerCase().split('_').join('-')")

        template(v-else)
          v-skeleton-loader(v-for="n in 10", :key="n" type="article")
        //- .debug {{submissions}}
</template>

<script>
import { get, call } from 'vuex-pathify'
import MessageInList from '@/components/Incoming/MessageList/MessageInList.vue'
import NoSubmissionsYet from '@/components/Incoming/MessageList/NoSubmissionsYet.vue'
import sortBy from 'lodash.sortby'
export default {
  name: 'MessageList',
  components: { MessageInList, NoSubmissionsYet },
  props: { type: { type: String, default: null } },
  data() {
    return {
      initComplete: false
    }
  },
  created() {
    if (!this.allSubmissions || this.allSubmissions.length < 1) {
      this.doRefresh()
    } else {
      this.initComplete = true
    }
  },
  computed: {
    ...get({
      allSubmissions: 'submissions/submissions',
      completed: 'submissions/completed',
      pending: 'submissions/pending',
      archived: 'submissions/archived',
      recentlyReviewed: 'submissions/recentlyReviewed',
      allSubmissionsOrderedByDate: 'submissions/allSubmissionsOrderedByDate',
      userData: 'user/userData',
      activeComponent: 'nav/activeComponent'
    }),
    title() {
      if (this.type === 'PENDING') return 'Waiting for Review'
      else if (this.type === 'COMPLETED') return 'Feedback Ready'
      else if (this.type === 'ARCHIVED') return 'Archived'
      else if (this.type === 'RECENTLY_REVIEWED') return 'Recently Reviewed'
      else if (this.type === 'ALL_SUBMISSIONS') return 'All Submissions'
      else return 'Submissions'
    },
    submissions() {
      let unsorted = []
      if (this.type === 'COMPLETED') unsorted = this.completed
      else if (this.type === 'PENDING') unsorted = this.pending
      else if (this.type === 'ARCHIVED') unsorted = this.archived
      else if (this.type === 'RECENTLY_REVIEWED') unsorted = this.recentlyReviewed
      else if (this.type === 'ALL_SUBMISSIONS') unsorted = this.allSubmissionsOrderedByDate
      else unsorted = []

      const sorted = sortBy(unsorted, [itm => itm.options && itm.options.isPremium ? 1 : 0, 'created'], ['desc', 'asc']).reverse()
      return sorted
    }
  },
  methods: {
    ...call({
      bindSubmissionsRef: 'submissions/bindSubmissionsRef'
    }),
    async doRefresh() {
      this.initComplete = false
      await this.bindSubmissionsRef()

      this.$nextTick(() => {
        setTimeout(() => {
          this.initComplete = true
          window.scrollTo(0, 1)
        }, 1500)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.max-width {
  max-width: 1200px;
}
h1 {
  cursor: default;
  font-size:1.5rem;
}

.header .section {
  color: $secondary;
}

.header.color--completed .section {
  color: $completed;
}

.header.color--archived .section {
  color: $archived;
}
.header.color--pending .section {
  color: $pending;
}
.header.color--all-submissions .section {
  color: $allSubmissions;
}

@media only screen and (min-width: 600px) {
  h1{ font-size: inherit}
}
</style>
