<template lang="pug">
  .bottom-nav
    v-bottom-navigation(app, horizontal, fixed, grow, color="red" v-model="activeItem")
        v-btn(@click="navTo(links.submit.link)")
          span {{links.submit.text}}
          v-icon.mr-2 {{links.submit.icon}}

        BottomNavMenu(:text="links.inbox.text", :link="links.inbox.link", :icon="links.inbox.icon")
        v-btn.nothing(v-show="false") {{activeItem}}
</template>

<script>
import { get, call } from 'vuex-pathify'
import BottomNavMenu from '@/components/BottomNavMenu'
export default {
  name: 'BottomNavigation',
  components: { BottomNavMenu },
  watch: {
    $route(to) {
      if (to.path.includes('action/submit')) {
        this.activeItem = 0
      } else if (to.path.includes('action/profile')) {
        this.activeItem = 2
      } else if (to.path.includes('/inbox/')) {
        this.activeItem = 1
      } else {
        this.activeItem = 2
      }
    }
  },
  data() {
    return {
      activeItem: null,
      links: {
        submit: { text: 'Submit Text', link: '/inbox/action/submit', icon: 'mdi-email-edit' },
        inbox: { text: 'Inboxes', link: '/inbox/pending', icon: 'mdi-inbox' },
        profile: { text: 'Info', icon: 'mdi-dots-horizontal', link: '/profile' }
      }
    }
  },
  computed: {
    ...get({
      activeComponent: 'nav/activeComponent',
      submissions: 'submissions/submissions',
      completed: 'submissions/completed',
      recentlyReviewed: 'submissions/recentlyReviewed',
      pending: 'submissions/pending',
      archived: 'submissions/archived',
      userData: 'user/userData'
    }),
    isSubmit() {
      return this.activeComponent && this.activeComponent.component.type === 'COMPOSE'
    },
    isInbox() {
      return this.activeComponent && this.activeComponent.component.name === 'MessageList'
    }
  },
  mounted() {},
  methods: {
    ...call({ navTo: 'nav/navTo' }),
    calcColorClass(idx) {
      if (this.activeItem === idx) return 'grey lighten-2'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
// ::v-deep
//   .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
//   background-color: white !important;
// }

// .bottom-nav
//   ::v-deep
//   .v-item-group.v-bottom-navigation
//   .v-btn.active
//   .v-btn__content
//   > *:not(.v-icon) {
//   color: $primary;
// }
</style>
