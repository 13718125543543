<template lang="pug">
    .line-by-line-reply.max-width.ml-0
        //- pre responses = {{responses}}
        //- pre lockedLines = {{lockedLines}}
        v-virtual-scroll(:bench="5", :items="lines", :height="1000", item-height="350")
          template(v-slot:default="{ item, index }")
            //- pre.debug line = {{item}}
            //- pre.debug idx = {{index}}

            .preview
                p.submitted-line.grey--text {{item}}
                .preview.my-3.d-flex.align-start
                    v-icon.mx-2 {{!responses[index] || responses[index] === item  ? 'mdi-check' : 'mdi-circle-edit-outline'}}
                    | {{responses[index] || item}}
                //- MarkedDown(v-if="responses[index]", :text="responses[index]" :showReadMore="false")
            .mx-md-3
                .wrapper.pa-1(:class="{responded: responses[index] && responses[index].length > 0, locked: lockedLines.includes(index)}")
                    v-textarea(:ref="'textarea-' + index", rows="3" flat color="success" placeholder="Use the buttons below to mark this line as okay, or copy + fix it.", hide-details v-model="responses[index]", :disabled="lockedLines.includes(index)" @change="$emit('change', responses)",).grey.lighten-4.ma-0.pa-5
                .actions.text-right.d-flex.justify-space-between.align-center.mt-3
                    .left
                        v-btn.ml-2(:disabled="lockIconIsDisabled(index)", icon small color="black" @click="markLineAsOkay(index)" title="(DANGER) Mark this line correct by replacing your reply with the original text.")
                            v-icon mdi-check-circle
                        v-btn.ml-2(:disabled="lockIconIsDisabled(index)", icon small color="black" @click="resetLine(index)" title="(DANGER) Overwrite this box with the original text.")
                            v-icon mdi-content-copy
                    .right
                        v-btn.ml-2(icon small color="secondary lighten-1" @click="toggleLockedLine(index)" title="Lock this reply box, to avoid accidental errors")
                            v-icon(:color="lockIconColor(index)") {{lockedLines.includes(index) ? 'mdi-lock-check' : 'mdi-lock-outline'}}
        </template>

<script>
const MarkedDown = () => import('@/components/MarkedDown.vue')
export default {
  name: 'LineByLineReply',
  components: { MarkedDown },
  props: { lines: { type: Array, required: true } },
  data() {
    return {
      responses: [],
      lockedLines: []
    }
  },
  created() {
    // this.init()
  },
  methods: {
    init() {
      this.lines.forEach(line => this.responses.push({ line }))
      this.$emit('change', this.responses)
    },
    toggleLockedLine(lineNum) {
      if (this.lockedLines.find(itm => itm === lineNum) >= 0) {
        this.lockedLines = this.lockedLines.filter(itm => itm !== lineNum)
      } else {
        this.lockedLines.push(lineNum)
      }
    },
    resetLine(lineNum) {
      this.$set(this.responses, lineNum, this.lines[lineNum])
      this.lockedLines = this.lockedLines.filter(itm => itm !== lineNum)
      this.$emit('change', this.responses)
    },
    markLineAsOkay(lineNum) {
      this.$set(this.responses, lineNum, this.lines[lineNum])
      this.$emit('change', this.responses)

      // //   scroll to bottom of textarea
      // const elem = this.$refs['textarea-' + lineNum][0].$el
      // const textArea = elem.querySelector('textarea')
      // this.$nextTick(() => {
      //   textArea.scrollTop = textArea.scrollHeight
      // })

      this.lockedLines.push(lineNum)
    },
    lockIconIsDisabled(lineNum) {
      const lineNumIsLocked = this.lockedLines && this.lockedLines.includes(lineNum)
      const responseIsAlreadyCheckmarked = !!(this.responses && this.responses[lineNum] && this.responses[lineNum] === this.lines[lineNum])
      return lineNumIsLocked || responseIsAlreadyCheckmarked
    },
    lockIconColor(lineNum) {
      if (this.lockedLines && this.lockedLines.includes(lineNum)) {
        return 'success'
      } else {
        return 'grey darken-2'
      }
    }
  },
  computed: {
    // lines() {
    //   return this.text && this.text.length > 1
    //     ? this.text.split('\n').filter(Boolean)
    //     : [this.text]
    // }

  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.line-by-line-reply {
  border-bottom: 2px dashed $secondary;
}
.submitted-line {
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height:1.85;
}

.wrapper {
  border: 2px solid rgb(215, 215, 215);
}
.wrapper.locked{
    border: 2px solid $success;
    border-style: dashed;
}

.wrapper.responded {
  border: 2px solid $success;
}
.wrapper.responded.locked {
  border: 2px dotted $success;

}

.line-by-line-reply ::v-deep .v-textarea textarea{
    line-height:2;
}

</style>
