<template lang="pug">
    v-card.rating.mb-5.mb-md-2.pa-md-3(flat)
      .d-flex.align-md-center.justify-md-space-between.flex-column.flex-md-row
        span {{title}}
        v-rating(:value="value", :color="readOnly ? 'primary' : 'primary darken-1'", :background-color="readOnly ? 'primary lighten-3' : 'info'", :readonly="readOnly", length="3" @input="$emit('input', {type, value: $event})")
</template>

<script>
import { get, call } from 'vuex-pathify'
export default {
  name: 'Rating',
  props: {
    title: { type: String, required: true },
    type: { type: String, required: true },
    readOnly: { type: Boolean, default: true }
  },
  computed: {
    ...get({ submission: 'submissions/submission' }),
    value() {
      if (
        this.submission &&
        this.submission.ratings &&
        this.submission.ratings[this.type]
      ) {
        return this.submission.ratings[this.type]
      } else {
        return null
      }
    }
  },
  methods: {
    ...call({})
  }
}
</script>

<style scoped lang="scss">
.rating span {
  cursor: default;
  font-size:1.25rem;
}
</style>
