<template lang="pug">
    .list-of-orders(v-if="items && items.length > 0")
      p.text-center
        b {{items.length}} Recent Orders
      v-data-table(:items="items", :headers="headers" disable-pagination hide-default-footer :hide-default-header="$vuetify.breakpoint.smAndDown")
        template(v-slot:item.created="{ item }")
          div(v-if="$vuetify.breakpoint.smAndDown")
              strong.mr-1 Date
              | {{ asDate(item.created) }}
          .text-left(v-else) {{ asDate(item.created) }}

        template(v-slot:item.description="{ item }")
          div(v-if="$vuetify.breakpoint.smAndDown")
              strong.mr-1 Description
              | {{ item.description }}
          .text-left(v-else) {{ item.description }}

        template(v-slot:item.amount="{ item }")
          div(v-if="$vuetify.breakpoint.smAndDown")
              strong.mr-1 Amount
              | $ {{ item.amount / 100 }}
          .text-left(v-else) $ {{  item.amount / 100 }}

        template(v-slot:item.receiptUrl="{ item }")
          div(v-if="$vuetify.breakpoint.smAndDown")
              a(:href="item.receiptUrl" target="_blank") Receipt Link
          .text-left(v-else)
            a(:href="item.receiptUrl" target="_blank") Receipt Link

        template(v-slot:item.status="{ item }")
          div(v-if="$vuetify.breakpoint.smAndDown")
              strong.mr-1 Status
              .d-inline-block.text-capitalize {{item.status}}
          .text-left(v-else)
            .text-capitalize {{item.status}}
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'ListOfOrders',
  components: { },
  props: {

  },
  data() {
    return {
      items: [],
      headers: [
        {
          text: 'Date',
          value: 'created',
          align: 'start'
        },
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Amount', value: 'amount' },
        { text: 'Receipt', value: 'receiptUrl', sortable: false },
        { text: 'Status', value: 'status' }
      ]
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...get({ company: 'user/company' })
  },
  methods: {
    async init() {
      const apiUrl =
            document.location.origin + '/.netlify/functions/stripe-helpers'
      const stripePayload = {
        stripeCustomerId: this.company.stripeCustomerId,
        requestType: 'LIST_ORDERS'
      }

      // window.debug('sending stripe payload', stripePayload)
      const res = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(stripePayload)
      }).catch(err => {
        console.error(err)
        this.loading = false
      })

      const txt = await res.text()
      if (txt) {
        const data = JSON.parse(txt)
        this.items = data
      }
    },
    asDate(seconds) {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }

      const date = new Date(seconds * 1000)
      return date.toLocaleDateString('en-US', options)
    }
  }
}
</script>
<style lang="scss" scoped>
.savings {
}
</style>
