import { db } from '@/firebase.js'
import { make } from 'vuex-pathify'
import Firebase from 'firebase/app'
const state = { replies: [], unreadGlobalReplies: [] }

const getters = {
  ...make.getters(state)

}

const mutations = {
  ...make.mutations(state)
}

const actions = {
  ...make.actions(['submission']),

  submitReply: async ({ getters, rootGetters, dispatch }, payload) => {
    const { text } = payload

    const submissionId = rootGetters['submissions/submission'].id
    const companyCode = rootGetters['submissions/submission'].companyCode
    // const userId = rootGetters['submissions/submission'].userId
    const myUserId = rootGetters['user/userData'].id
    const userIdOfSubmission = rootGetters['submissions/submission'].userId

    // console.log('About to submit reply to company code', companyCode, 'submissionId', submissionId, 'userId', myUserId)

    const created = new Date()
    const obj = { companyCode, submissionId, userId: myUserId, text, created, submissionCreatedBy: userIdOfSubmission }

    console.log('about to set reply in replies db', obj)
    const refReply = await db.collection('replies').doc()
    console.log('about to set replyId', refReply.id)
    await refReply.set({ ...obj, id: refReply.id })
    await db.collection('repliesLedger').doc(refReply.id).set({ created: new Date(), submissionId, submissionCreatedBy: userIdOfSubmission, wasReadBy: [] })
    console.log('finshed creating reply')

    const increment = Firebase.firestore.FieldValue.increment(1)
    await db.collection('submissions').doc(companyCode).collection(userIdOfSubmission).doc(submissionId).update({ numReplies: increment })
    console.log('finshed incrementing submission replies counts')
  },
  markReplyWasRead: async ({ getters, rootGetters, dispatch }, payload) => {
    const { replyId } = payload
    const myUserId = rootGetters['user/userData'].id

    console.log('replyId = ', replyId)
    console.log('myUserId = ', myUserId)
    const key = `wasReadBy.${myUserId}`
    db.collection('repliesLedger').doc(replyId).update({ [key]: true })
  },
  deleteReply: async ({ getters, rootGetters, dispatch }, payload) => {
    const { id: replyId } = payload
    await db.collection('replies').doc(replyId).delete()

    // const myUserId = rootGetters['user/userData'].id
    const userIdOfSubmission = rootGetters['submissions/submission'].userId
    const submissionId = rootGetters['submissions/submission'].id
    const companyCode = rootGetters['submissions/submission'].companyCode

    const decrement = Firebase.firestore.FieldValue.increment(-1)
    await db.collection('submissions').doc(companyCode).collection(userIdOfSubmission).doc(submissionId).update({ numReplies: decrement })
  },
  bindRepliesForSubmissionId: async ({ commit, rootGetters }, payload) => {
    const { submissionId, userId } = payload
    // const { submissionId, userId, companyCode } = payload

    // console.log('about to bind replies for submission companyCode, userId,submissionId ', companyCode, userId, submissionId)

    const query = db
      .collection('replies')
      .where('submissionId', '==', submissionId)
      .where('submissionCreatedBy', '==', userId)

    query.onSnapshot(
      querySnapshot => {
        const obj = querySnapshot.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        })
        commit('SET_REPLIES', obj)
      },
      err => {
        console.log(`Encountered error: ${err}`)
      }
    )
  },
  bindUnreadGlobalReplies: async ({ commit, rootGetters }, payload) => {
    const userId = Firebase.auth().currentUser.uid

    console.log('About to get replies for submissions created by user', userId)
    const query = db
      .collection('repliesLedger')
      .where('submissionCreatedBy', '==', userId)
      .where(`wasReadBy.${userId}`, '!=', true)

    query.onSnapshot(
      querySnapshot => {
        const obj = querySnapshot.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        })
        console.log('Setting object to', obj)
        commit('SET_UNREAD_GLOBAL_REPLIES', obj)
      },
      err => {
        console.log(`Encountered error: ${err}`)
      }
    )
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
