import Vue from 'vue'
import store from '@/store/index'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const determineLanguage = (query, params) => {
  if (params && params.lang) {
    return params.lang
  } else if (
    query && query.lang
  ) {
    return query.alng
  } else if (navigator && navigator.language) {
    return navigator.language
  } else return 'en'
}
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requiresAuth: false,
      isStatic: true
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ '../views/Static/Home.vue'
      )
  },
  {
    path: '/ru',
    redirect: { name: 'Home', query: { lang: 'ru' } }
  },
  {
    path: '/kr',
    redirect: { name: 'Home', query: { lang: 'ko' } }
  },
  {
    path: '/ko',
    redirect: { name: 'Home', query: { lang: 'ko' } }
  },
  {
    path: '/en',
    redirect: { name: 'Home', query: { lang: 'en' } }
  },
  {
    path: '/bootcamp',
    name: 'BootCamp',
    meta: {
      requiresAuth: false,
      isStatic: true
    },
    component: () =>
      import(
        /* webpackChunkName: "bootcamp" */ '../views/Static/BootCamp.vue'
      )
  },

  {
    path: '/app',
    redirect: { name: 'DashboardOfType', params: { type: 'pending' } }
  },
  {
    path: '/inbox/:type',
    name: 'DashboardOfType',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
      /* webpackChunkName: "submissions" */ '../views/Incoming/Dashboard.vue'
      )
  },
  {
    path: '/inbox/action/:action',
    name: 'DashboardWithAction',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
      /* webpackChunkName: "submissions" */ '../views/Incoming/Dashboard.vue'
      )
  },
  {
    path: '/inbox/messages/:action/:companyCode/:userId/:submissionId',
    name: 'DashboardWithMessagesAction',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
      /* webpackChunkName: "submissions" */ '../views/Incoming/Dashboard.vue'
      )
  },
  {
    path: '/pages/:action/:id',
    name: 'Confidentiality',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
      /* webpackChunkName: "submissions" */ '../views/Incoming/Dashboard.vue'
      )
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      requiresAuth: false,
      admin: true
    },
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/User/Login.vue')
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    meta: {
      requiresAuth: false,
      admin: true
    },
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/User/SignUp.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      requiresAuth: false,
      admin: true
    },
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/User/Register.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      requiresAuth: true
    },
    redirect: { name: 'DashboardOfType', params: { type: 'pending' } }
  },
  {
    path: '/*',
    redirect: { name: 'Home' }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 1 }
  }
})

router.beforeEach(async (to, from, next) => {
  // console.log('Processing beforeEach...')
  const lang = determineLanguage(to.params, to.query)
  // console.log('lang = ', lang)
  await store.dispatch('user/setLang', lang)

  const { companyCode, firstName, email } = to.query

  if (companyCode && firstName && email) {
    // query params have enough to sign in
    await store.dispatch('user/signIn', { code: companyCode, firstName, email })
    next(to.path)
  } else {
    // no query params, so either show page or redirect to login
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    const companyName = store && store.getters && store.getters['user/company'] ? store.getters['user/company'].name : null
    if (requiresAuth && !companyName) {
      next('/login?originPath=' + to.fullPath)
    } else {
      next()
    }
  }
})

export default router
