<template lang="pug">
    .tags
        h2.mb-4 Tags
        v-chip(v-for="tag, idx in tags" outlined color="accent", :key="tag + idx").ma-2 {{tag}}
        p(v-if="!tags || tags.length < 1") Next time you can try "tagging" your submission to give us some context, such as "professional", "casual", "joking", "new customer", etc
</template>

<script>
export default {
  name: 'Tags',
  props: { tags: { type: Array, default: () => [] } }
}
</script>
