<template lang="pug">
    .adjust-submitted-text
        p
            strong.mr-2 Optional:
            | If they wrote everything as one large paragraph, you might want to separate their text into shorter paragraphs or single sentences, so that you can respond to each chunk individually.
        template(v-if="showAdjustInput")
            v-textarea(rows="6", :value="text", @change="$emit('change', $event)", color="grey lighten-5" outlined hide-details)
            v-btn(outlined color="secondary" @click="showAdjustInput = false").mt-4 Done adjusting
                v-icon.ml-1 mdi-check-circle

        template(v-else)
            v-btn(outlined color="secondary" @click="showAdjustInput = true") Yes, adjust it
</template>

<script>
export default {
  name: 'AdjustSubmittedText',
  props: { text: { type: String, required: true } },
  data() {
    return {
      showAdjustInput: false
    }
  }
}
</script>

<style scoped lang="scss">

</style>
