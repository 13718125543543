var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items && _vm.items.length > 0)?_c('div',{staticClass:"list-of-orders"},[_c('p',{staticClass:"text-center"},[_c('b',[_vm._v(_vm._s(_vm.items.length)+" Recent Orders")])]),_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","hide-default-header":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('strong',{staticClass:"mr-1"},[_vm._v("Date")]),_vm._v(_vm._s(_vm.asDate(item.created)))]):_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.asDate(item.created)))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('strong',{staticClass:"mr-1"},[_vm._v("Description")]),_vm._v(_vm._s(item.description))]):_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(item.description))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('strong',{staticClass:"mr-1"},[_vm._v("Amount")]),_vm._v("$ "+_vm._s(item.amount / 100))]):_c('div',{staticClass:"text-left"},[_vm._v("$ "+_vm._s(item.amount / 100))])]}},{key:"item.receiptUrl",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('a',{attrs:{"href":item.receiptUrl,"target":"_blank"}},[_vm._v("Receipt Link")])]):_c('div',{staticClass:"text-left"},[_c('a',{attrs:{"href":item.receiptUrl,"target":"_blank"}},[_vm._v("Receipt Link")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('strong',{staticClass:"mr-1"},[_vm._v("Status")]),_c('div',{staticClass:"d-inline-block text-capitalize"},[_vm._v(_vm._s(item.status))])]):_c('div',{staticClass:"text-left"},[_c('div',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.status))])])]}}],null,false,3351622859)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }